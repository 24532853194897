.copy-button{
    &__feedback{
        display: none;
        pointer-events: none;
        position: absolute;
        bottom: -40px;
        padding: 4px 8px;
        background-color: $light-green;
        color: $primary;
        font-size: 14px;
        white-space: nowrap;

        &.active{
            display: block;
        }

        &::before{
            content: "";
            display: block;
            position: absolute;
            top: -6px;
            transform: rotate(45deg);
            left: 12px;
            width: 12px;
            height: 12px;
            background-color: $light-green;
            z-index: -1;
        }
    }
}