.facts{
    padding: 80px 0;

    @include max-lg{
        padding: 40px 0;
    }

    &--large{
        .facts__content{
            .text-wrapper{
                @include max-md{
                    gap: 0;
                }
            }
        }

        .facts__title{
            @extend .title--h1;
        }

        .facts__text{
            @extend .ingress;
        }
    }

    &__wrapper{
        display: flex;
        flex-direction: row;
        padding-bottom: 20px;

        @include max-md{
            flex-direction: column;
            padding-bottom: 0;
        }
    }

    &__content{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        background-color: $dark-green;
        padding: 60px 110px;
        aspect-ratio: 16/10;
        transform: translateY(20px);

        @include max-xl{
            padding: 56px;
        }

        @include max-md{
            width: calc(100% - 8px);
            padding: 56px 32px 32px;
            aspect-ratio: initial;
            transform: translateY(0);
        }
        
        .text-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
            height: 280px;
            overflow: hidden;
            
            @include max-md{
                height: inherit;
                overflow: initial;
            }
        }
    }

    &__title{
        color: $sprout-green;
        @extend .title;
        @extend .title--h4;
    }

    &__text{
        @extend .body-text;
        color: $white;
    }

    &__symbol{
        position: absolute;
        top: calc(50% - 56px);
        left: calc(100% - 35px);
        width: 70px;
        height: 78px;
        z-index: 1;

        @include max-md{
            width: 48px;
            height: 54px;
            top: -30px;
            left: calc(50% - 27px);
        }

        path{
            fill: $sprout-green;
        }
    }

    &__figure{
        width: 50%;

        @include max-md{
            width: calc(100% - 8px);
            margin-left: 8px;
            order: -1;
        }

        picture{
            display: block;
            height: 100%;
            width: 100%;
            aspect-ratio: 16/10;

            img{
                display: block;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}