.image-grid{
    padding: 40px 0;

    &__header{
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 40px;

        @include max-lg{
            margin-bottom: 24px;
        }
    }

    &__title{
        @extend .title;
        @extend .title--h3;
    }

    &__intro{
        @extend .body-text;
    }

    &__grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: $grid-gutter-width;
        grid-row-gap: $grid-gutter-width;
        
        @include max-xl{
            grid-template-columns: repeat(3, 1fr);
        }
        
        @include max-lg{
            grid-column-gap: $grid-gutter-width-sm;
            grid-row-gap: $grid-gutter-width-sm;
        }
        
        @include max-md{
            grid-template-columns: repeat(2, 1fr);
        }
        
        @include max-sm{
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 40px;
        }
    }

    &__card{
        border: 2px solid;

        &--correct{
            border-color: $dark-green;

            .image-grid__card-title{
                background-color: $dark-green;

                &::before{
                    background-image: url('./../images/icons/check-circle-filled-white.svg');
                }

                .correct{
                    display: block;
                }
            }
        }
        
        &--error{
            border-color: $dark-rust;
            
            .image-grid__card-title{
                background-color: $dark-rust;
                
                &::before{
                    background-image: url('./../images/icons/warning-filled-white.svg');
                }

                .error{
                    display: block;
                }
            }
        }
    }

    &__card-title{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
        color: $white;
        @extend .body-text;

        @include max-lg{
            font-size: 20px;
        }

        &::before{
            content: "";
            display: block;
            flex-shrink: 0;
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }

        span{
            display: none;
        }
    }

    &__figure{
        padding: 24px;

        img{
            width: 100%;
            display: block;
        }
    }
}