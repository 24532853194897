@media print {
    body {
        -webkit-print-color-adjust: exact;
    }

    main {
        padding-bottom: 0px !important;
    }

    ps-header,
    ps-footer,
    .test-results .alert-banner,
    .test-results .button-group,
    .accordion-button svg,
    .accordion__toggle-all {
        display: none !important;
    }

    .accordion-item .accordion-collapse {
        display: block;
    }

    .accordion-button {
        background-color: inherit !important;
    }

    .accordion-item,
    .test-results .checklist,
    .test-results .category-summary__card {
        page-break-inside: avoid;
    }

    .transport-cards a {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}