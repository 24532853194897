.question-group{
    position: relative;

    &--results{
        &:last-child{
            margin-bottom: -160px;

            @include max-lg{
                margin-bottom: -120px;
            }
        }

        &::after{
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            right: 0;
            width: calc((100% - #{$screen-xxl} + #{$container-gutter * 2} + 1px) / 2);
            min-width: $container-gutter;
            height: 100%;
            background-color: $light-green;

            @include max-xxl{
                width: 80px;
            }

            @include max-lg{
                width: 16px;
                min-width: initial;
            }

            @include max-md{
                display: none;
            }
        }

        .question-group__heading{
            @extend .offset-xxl-1;
            @extend .col-md-6;
            @extend .offset-md-0;
        }
    }

    &__heading{
        padding: 120px 0;
        @extend .col-xxl-6;
        @extend .offset-xxl-3;
        @extend .col-md-8;
        @extend .offset-md-2;

        @include max-lg{
            padding: 40px 0;
        }
    }

    &__pre-title{
        margin-bottom: 16px;
        color: $primary;
        @extend .title;
        @extend .title--h3;

        @include max-lg{
            margin-bottom: 8px;
        }
    }

    &__title{
        color: $primary;
        @extend .title;
        @extend .title--h1;
    }

    &__ingress{
        color: $primary;
        @extend .ingress;

        &:not(:first-child){
            margin-top: 24px;

            @include max-lg{
                margin-top: 16px;
            }
        }
    }

    &__button{
        &:not(:first-child){
            margin-top: 80px;

            @include max-lg{
                margin-top: 40px;
            }
        }
    }

    &__results{
        @extend .col-xxl-4;
        @extend .offset-xxl-1;
        @extend .col-xl-5;
        @extend .offset-xl-1;
        @extend .col-md-6;

        .wrapper{
            height: 100%;
            padding: 120px 0 120px 80px;
            background-color: $light-green;

            @include max-lg{
                padding: 40px 0px 40px 16px;
            }

            @include max-md{
                padding: 24px 16px;
                margin: 0 #{-$container-gutter-sm};
            }
        }
    }

    &__results-title{
        @extend .title;
        @extend .title--h4;
    }

    &__results-text{
        margin-top: 8px;
        @extend .body-text;
    }

    .score-group, .score, .speedometer{
        margin: 40px 0;

        &:first-child{
            margin-top: 0;
        }

        &:last-child{
            margin-bottom: 0;
        }

        @include max-lg{
            margin: 16px 0;
        }
    }
}