.share-macro {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   padding-block: 40px;
   gap: 16px;

   &__label {
      @include body-text("bold")
   }

   &__list {
      display: flex;
      gap: 32px;
   }

   &__item {
      img {
         height: 34px;
         width: auto;
      }
   }
}