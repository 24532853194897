.test-summary{
    &__wrapper{
        display: flex;
        flex-direction: column;
        gap: 80px;

        @include max-lg{
            gap: 40px;
        }
    }

    &__heading{
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include max-lg{
            gap: 24px;
        }
    }

    &__title{
        color: $primary;
        @extend .title;
        @extend .title--h1;
    }

    &__ingress{
        @extend .ingress;
    }

    &__evaluation{
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 40px;

        @include max-lg{
            padding: 32px;
        }

        &--high{
            background-color: $moss-green;

            .speedometer{
                &__title{
                    color: $sprout-green;
                }

                &__subtitle{
                    color: $white;
                }

                &__background{
                    fill: $white;
                    stroke: $moss-green;
                }
                
                &__fill{
                    fill: $sprout-green;
                    stroke: $moss-green;
                }
            }

            .test-summary__evaluation-text{
                color: $white;
            }
        }
        
        &--medium{
            background-color: $light-rust;

            .speedometer{
                &__title{
                    color: $dark-rust;
                }

                &__subtitle{
                    color: $black;
                }

                &__background{
                    fill: $gray-60;
                    stroke: $light-rust;
                }
                
                &__fill{
                    fill: $dark-rust;
                    stroke: $light-rust;
                }
            }

            .test-summary__evaluation-text{
                color: $black;
            }
        }
        
        &--low{
            background-color: $dark-rust;

            .speedometer{
                &__title{
                    color: $light-rust;
                }

                &__subtitle{
                    color: $white;
                }

                &__background{
                    fill: $white;
                    stroke: $dark-rust;
                }
                
                &__fill{
                    fill: $light-rust;
                    stroke: $dark-rust;
                }
            }

            .test-summary__evaluation-text{
                color: $white;
            }
        }

        &--self{
            background-color: $light-green;

            .score-group{
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
        }
    }

    &__evaluation-text{
        @extend .body-text;
    }
}