html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
*, *::before, *::after {
    box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
html{
	-webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

button{
	-webkit-appearance: none;
	border-radius: 0;
}

textarea,
input.text,
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"],
select {
	-webkit-appearance: none;
	border-radius: 0;
	font: inherit;
	color: $black;

	&:focus-visible {
		outline: 0;
		box-shadow: none;
	}
}

input[type="button"],
input[type="submit"] {
	-webkit-appearance: none;
	
	&:not(.btn){
		border-radius: 0;
		font: inherit;
		color: $black;
	
		&:focus-visible {
			outline: 0;
			box-shadow: none;
		}
	}
}

input[type="search"]{
	/* clears the ‘X’ from Internet Explorer */
	&::-ms-clear { display: none; width : 0; height: 0; }
	&::-ms-reveal { display: none; width : 0; height: 0; }
	/* style the ‘X’ from Chrome */
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration { display: none; }
}

input[type=number] {
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

button{
	border: none;
    margin: 0;
    padding: 0;
	background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
	cursor: pointer;

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}

figure{
    img{
        display: block;
        width: 100%;
    }
}