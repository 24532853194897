.hubspot-form{
    padding: 80px 0;

    @include max-lg{
        padding: 40px 0;
    }

    &__title{
        color: $primary;
        margin-bottom: 24px;
        @extend .title; 
        @extend .title--h2;

        @include max-lg{
            margin-bottom: 16px;
        }
    }

    &__ingress{
        margin-bottom: 32px;
        @extend .ingress;
    }

    .hbspt-form{
        margin-top: 32px;

        .field{
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
        
            &:last-child{
                margin-bottom: 0;
            }

            label{
                &:not(.hs-error-msg){
                    @extend .label;
                }
            }

            textarea,
            input[type="text"],
            input[type="number"],
            input[type="tel"],
            input[type="email"],
            input[type="password"],
            input[type="search"],
            select {
                @extend .text-input;
            }

            textarea{
                @extend .textarea;
            }
        }
    }

    .legal-consent-container, .hs-richtext{
        margin-top: 24px;
        margin-bottom: 24px;

        &:first-child{
            margin-top: 0;
        }

        &:last-child{
            margin-bottom: 0;
        }

        p{
            @include body-text;

            a{
                @include link-text;
            }
        }
    }

    .legal-consent-container{
        .hs-form-booleancheckbox-display{
            & > span{
                margin-left: 0;
            }
        }
    }

    select{
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url('./../images/icons/ChevronDown.svg');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 6px);
        background-position-y: 12px;
        padding-right: 32px !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
    }

    .hs-submit, .hs_submit{
        .actions{
            text-align: right;
        }
    }

    .hs_error_rollup{
        margin: 24px 0;
    }

    .hs-fieldtype-radio, .hs-fieldtype-checkbox{
        & > label{
            margin-bottom: 16px !important;
        }

        .error, .invalid{
            .hs-form-radio{
                label
                {
                    span {
                        color: $dark-rust;
            
                        &::before{
                            border-color: $dark-rust;
                            background-color: $light-rust;
                        }
                    }
                }
            }
        }
    }

    // Checkbox
    .hs-form-checkbox, .hs-form-booleancheckbox{
        @extend .checkbox;

        input{
            @extend .custom-checkbox;
        }
    }

    .hs-form-booleancheckbox{
        // Prevent wrong spacing when the markup is different than the expected
        &:last-child {
            margin-bottom: 16px;

            @include max-lg{
                margin-bottom: 8px;
            }
        }
    }

    // Radio
    .hs-form-radio, .hs-form-booleanradio{
        @extend .radio;

        input{
            @extend .custom-radio;
        }
    }

    // File
    .hs-fieldtype-file{
        position: relative;
        @extend .input-file-wrapper;
        // cursor: pointer;

        &:focus-within{
            &::before{
                border-style: solid;
                outline: 1px solid $primary;
            }
        }

        .input{
            &::after{
                content: "Finn filer +";
                margin-top: 24px;
                @extend .hs-button.secondary;
            }
        }

        input{
            // pointer-events: all !important;
            // position: absolute !important;
            // width: 100% !important;
            // height: 100% !important;
            // z-index: 999;
            // cursor: pointer;
        }
    }

    // Error message
    .hs-error-msg, .hs-error-msgs{
        @extend .error-text;
    }

    .hs-field-desc{
        @extend .input-group__description;
    }

    // Confirmation message
    .submitted-message{
        h2{
            margin-bottom: 24px;
            @extend .title;
            @extend .title--h2;

            @include max-lg{
                margin-bottom: 16px;
            }

            span{
                font-family: $sans-serif !important;
            }
        }

        h3{
            margin-bottom: 24px;
            @extend .title;
            @extend .title--h3;

            @include max-lg{
                margin-bottom: 16px;
            }

            span{
                font-family: $sans-serif !important;
            }
        }

        p{
            @include body-text;
            
            span{
                @include body-text;
                font-family: $sans-serif !important;
            }

            a{
                @include link-text;
            }
        }
    }
}

// Submit / primary buttons
.hs-button{
    &.primary{
        // Duplicated style (from .btn and .btn--primary)
        display: inline-flex;
        align-items: center;
        gap: 8px;
        padding: 10px 48px;
        border-radius: 999px !important;
        min-height: 50px;
        outline: none;
        border: none;
        cursor: pointer;
        background-color: $primary;
        color: $sprout-green !important;
        font-size: 20px !important;
        line-height: 1.5 !important;
        font-weight: $fw-regular !important;
    
        @include max-lg{
            font-size: 16px !important;
        }

        &:hover, &:active, &:focus-visible{
            background-color: $dark-green;
            color: $sprout-green !important;
        }
    }

    &.secondary{
        // Duplicated style (from .btn and .btn--primary)
        display: inline-flex;
        align-items: center;
        gap: 8px;
        padding: 8px 48px;
        border-radius: 999px !important;
        min-height: 50px;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 8px 48px;
        background-color: transparent;
        color: $primary !important;
        border: 2px solid $primary;
        font-size: 20px !important;
        line-height: 1.5 !important;
        font-weight: $fw-regular !important;

        @include max-lg{
            padding: 11px 48px;
            font-size: 16px !important;
        }
        
        &:hover, &:active, &:focus-visible{
            background-color: transparentize($moss-green, 0.95) !important;
            color: $primary !important;
        }

        &:active{
            border-color: $dark-green;
            box-shadow: 0 0 0 1px $dark-green;
        }
    }
}