.image-transport-card{
    padding: 80px 0;

    @include max-lg{
        padding: 40px 0;
    }

    &--reverse{
        .row{
            @include min-sm{
                flex-direction: row-reverse;
                justify-content: flex-end;
            }
        }

        .image-transport-card__content{
            @extend .offset-xl-1;
            @extend .offset-lg-0;
            @extend .offset-md-1;
        }

        .image-transport-card__figure{
            @extend .offset-xl-1;
            @extend .col-md-5;
            @extend .offset-md-1;
        }
    }

    &__heading{
        margin-bottom: 60px;
        color: $primary;
        @extend .title;
        @extend .title--h2;
        @extend .col-12;

        @include max-lg{
            margin-bottom: 40px;
        }
    }

    &__figure{
        @extend .col-xl-5;
        @extend .col-lg-5;
        @extend .col-md-5;
        @extend .col-sm-6;

        @include max-sm{
            margin-bottom: 24px;
        }
    }
    
    &__content{
        @extend .col-xl-5;
        @extend .offset-xl-1;
        @extend .col-lg-6;
        @extend .col-md-5;
        @extend .offset-md-1;
        @extend .col-sm-6;
    }

    &__title{
        margin-bottom: 24px;
        color: $primary;
        @extend .title;
        @extend .title--h2;

        @include max-lg{
            margin-bottom: 16px;
        }
    }

    &__text{
        @extend .body-text;
        margin-bottom: 40px;

        @include max-lg{
            margin-bottom: 24px;
        }
    }
    
    & + .image-transport-card {
        padding-top: 40px;
    
        @include max-lg{
            padding-top: 16px;
        }
    }
}
