.cookiebot{
    padding: 80px 0;

    @include max-lg{
        padding: 40px 0;
    }

    &__content{
        p{
            hyphens: none;
            @include body-text;
            
            @include max-lg{
                margin-bottom: 24px;
            }

            &:not(:last-child){
                margin-bottom: 30px;
            }
        }

        .CookieDeclarationType{
            @include max-lg{
                overflow-x: auto;
            }
        }

        .CookieDeclarationTable{
            @include max-lg{
                min-width: 900px;
            }
        }
    }
}