// Events part
.events{
    padding: 80px 0;

    @include max-lg{
        padding: 40px 0;
    }

    &__header{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 24px;
        row-gap: 16px;
        margin-bottom: 56px;
        
        @include max-lg{
            flex-direction: column;
            align-items: flex-start;
            row-gap: 8px;
            margin-bottom: 24px;
        }
    }

    &__title{
        color: $primary;
        @extend .title;
        @extend .title--h2;
    }

    .event-list{
        &__item{
            @include max-sm{
                padding-bottom: 56px;
            }
        }
    }

    // MACRO
    &--macro{
        margin: 80px 0;
        padding: 32px;
        border: 2px solid $cool-green;

        @include max-lg{
            padding: 24px;
            margin: 40px 0;
        }

        .events__header{
            flex-direction: column;
            align-items: flex-start;
            row-gap: 24px;

            @include max-lg{
                row-gap: 8px;
            }
        }

        .event-list{
            &__list{
                padding-left: 0;
            }

            &__item, &__item-detail{
                padding-left: 0;

                &::before{
                    display: none;
                }
            }

            &__item-details{
                padding-left: 0;
            }
        }
    }
}