.patentretningslinjer-list{
    padding: 40px 0;
    
    .html-area{
        & > ul{
            padding-left: 0;
            
            & > li{
                &:not(:last-child){
                    margin-bottom: 32px;
                }

                ul{
                    margin-top: 8px;

                    li{
                        &:not(:last-child){
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }

    }
}