.timeline{
    padding: 80px 0 0;

    @include max-lg{
        padding-left: 28px;
    }

    &__item{
        position: relative;
        padding-bottom: 80px;

        &::before{
            content: "";
            display: block;
            position: absolute;
            left: -24px;
            width: 2px;
            height: 100%;
            background-color: $green;
        }

        &::after{
            content: "";
            display: block;
            position: absolute;
            top: 40px;
            left: -31px;
            width: 16px;
            height: 16px;
            border-radius: 100px;
            background-color: $dark-blue;

            @include max-lg{
                top: 34px;
            }
        }

        &:last-child{
            &::before{
                height: 42px;
            }
        }
        
        &--applicant{
            .timeline__content{
                border: none;
                background-color: $light-blue;
            }

            .timeline__title{
                color: $dark-blue;
            }

            .timeline__time{
                color: $dark-blue;
            }

            .timeline__role{
                .icon{
                    path{
                        fill: $dark-blue;
                    }
                }

                p{
                    color: $dark-blue;
                }
            }
        }

        &--patentstyret{
            &::after{
                background-color: $moss-green;
            }

            .timeline__content{
                border: none;
                background-color: $light-green;
            }

            .timeline__time{
                color: $moss-green;
            }

            .timeline__role{
                .icon{
                    path{
                        fill: $moss-green;
                    }
                }

                p{
                    color: $moss-green;
                }
            }
        }
    }
    
    &__top{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        margin-bottom: 16px;
    }

    &__time{
        @extend .body-text;
        @extend .body-text--bold;
    }

    &__role{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        margin-left: auto;

        .icon{
            flex-shrink: 0;
            width: 24px;
            height: 24px;
        }

        p{
            @extend .body-text;
            white-space: nowrap;
        }
    }

    &__role-image{
        height: 36px;

        @include max-sm{
            height: 30px;
        }
    }
    
    &__content{
        padding: 56px 32px;
        border-radius: 0 56px 0 56px;
        background-color: $color-bg;
        border: 2px solid $moss-green;

        .html-area{
            p{
                &:not(:last-child){
                    @include min-lg{
                        margin-bottom: 24px;
                    }
                }
            }

            a{
                @extend .btn;
                @extend .btn--tertiary;
            }
        }
    }
    
    &__title{
        @extend .title;
        @extend .title--h3;
        margin-bottom: 24px;
        color: $moss-green;
   }
}