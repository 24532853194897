.dropzone{
    &--green{
        background-color: $light-green;

        &:last-child{
            margin-bottom: -160px;
            padding-bottom: 160px;
            
            @include max-lg{
                margin-bottom: -120px;
                padding-bottom: 120px;
            }
        }

        .image-notch{
            &::before, &::after{
                background-color: $light-green;
            }
        }
    }
}