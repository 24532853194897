$header-height-xl: 142px;
$header-height-lg: 204px;
$header-height-md: 82px;
$header-height-sm: 76px;

.main-header{
    padding: 58px 0 34px;

    @include max-lg{
        padding: 16px 0;
    }

    &--light-green{
        background-color: $light-green;
    }

    &__logo{
        order: 1;
        flex-shrink: 0;
        display: inline-block;
        width: 100px;
        margin-right: auto;

        @include max-md{
            width: 70px;
        }

        &:focus-visible{
            outline: 2px solid $moss-green;
            outline-offset: 10px;
        }

        img{
            display: block;
            width: 100%;
        }
    }

    &__skip{
        position: absolute;
        left: -300px;

        &:focus-within{
            position: relative;
            left: inherit;
            order: 2;
            margin-right: auto;
        }

        a{
            width: 1px;
            height: 1px;
            opacity: 0;
            pointer-events: none;
        }

        a{
            &:focus-visible{
                width: auto;
                height: auto;
                opacity: 1;
                pointer-events: all;
            }
        }
        
    }

    &__wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: 32px;

        @include max-md {
            row-gap: 0;
        }

        @include max-sm{
            column-gap: 16px;
        }

        .arrow-link{
            margin-bottom: 0;
            max-width: 240px;
        }
    }
    
    &__links{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 32px;
        order: 3;
        max-width: 33%;
        
        @include max-xl{
            order: 6;
            width: 100%;
            max-width: 100%;
            justify-content: flex-end;
            margin-left: auto;
        }

        @include max-lg{
            display: none;
        }

        .arrow-link {
            a {
                text-decoration: none;
            }

            .icon {
                display: none;
            }
        }
    }

    &__search-btn{
        order: 3;

        @include max-md{
            display: none;
        }
    }

    &__menu-btn{
        order: 4;

        @include min-md{
            width: 162px;
            white-space: nowrap;
            justify-content: center;
        }

        @include max-md{
            min-height: inherit;
            border: none;
            padding: 7px 2px;
            border-radius: 0;
            font-size: 20px;
        }

        &:hover, &:focus-visible{
            @include min-md {
                .icon--menu{
                    > .bar{
                        background-color: $white;

                        @include min-lg{
                            &:first-child{
                                transform: translateY(-1.5px);
                            }
        
                            &:last-child{
                                transform: translateY(1.5px);
                            }
                        }
                    }
                }
            }
        }

        .text{
            &--close{
                display: none;
            }
        }

        .icon{
            margin-right: -12px;
            
            @include max-lg{
                margin-right: 0 !important;
            }
            
            &--menu{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 2px;
                
                > .bar{
                    width: 14px;
                    height: 2px;
                    background-color: $primary;
                    transition: transform 0.15s ease-out;
                }

                &.hidden-max-md {
                    @include max-md {
                        display: none;
                    }
                }

                &.hidden-min-md {
                    @include min-md {
                        display: none;
                    }
                }
            }

            &--close{
                display: none;
            }
        }
    }

    &__menu-content{
        display: none;
        order: 7;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 40px;

        & > * {
            width: 100%;
            max-width: 400px;

            @include max-lg{
                width: calc(50% - 8px);
                max-width: 100%;
            }

            @include max-md{
                width: 100%;
            }
        }

        @include max-lg{
            column-gap: 16px;
        }

        @include max-md{
            row-gap: 56px;
        }
    }

    &__menu-top{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 32px;
        width: 100%;
        max-width: 100%;
        margin-bottom: 160px;

        @include max-lg{
            margin-bottom: 56px;
        }

        @include max-md{
            order: 3;
            flex-direction: column;
            gap: 16px;
            margin-bottom: 0;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &__menu-links{
        display: flex;
        flex-direction: column;

        .arrow-link{
            max-width: initial;

            a{
                display: inline-flex;
                gap: 16px;
                padding: 16px 10px 16px 8px;
                text-decoration: none;
                color: $black;
                @extend .title;
                @extend .title--h2;

                @include max-lg{
                    gap: 8px;
                }

                .icon{
                    width: 32px;
                    height: 32px;

                    @include max-lg{
                        width: 24px;
                        height: 24px;
                    }

                    path{
                        fill: $black;
                    }
                }

                &:focus-visible, &:hover{
                    background-color: $black;
                    color: $white;

                    .icon{
                        path{
                            fill: $white;
                        }
                    }
                }
            }
        }
    }

    &--menu-open{
        height: 100vh;
        max-height: 100vh;
        padding-bottom: 128px;
        background-color: $green-20;
        transition: background-color 0.3s;
        overflow-y: scroll;

        @include max-md{
            height: inherit;
            padding-bottom: 56px;

            @supports (-webkit-touch-callout: none) {
                padding-bottom: 128px;
            }
        }

        .main-header__menu-top{
            .arrow-link{
                a {
                    @include min-md {
                        text-decoration: none;
                    }
                }
                
                &--hide-xl{
                    @include min-xl{
                        display: none;
                    }
                }
            }
        }

        .main-header__links{
            @include max-xl{
                display: none;
            }
        }

        .main-header__search-btn{
            @include max-md{
                display: flex;
                order: 5;
                width: 100%;
                justify-content: center;
                margin-block: 40px 56px;
            }
        }
        
        .main-header__menu-btn{
            .text{
                display: none;

                &--close{
                    display: block;
                }
            }

            .icon{
                display: none;

                &--close{
                    display: block;

                    &.hidden-max-md {
                        @include max-md {
                            display: none;
                        }
                    }

                    &.hidden-min-md {
                        @include min-md {
                            display: none;
                        }
                    }
                }
            }
        }

        .main-header__menu-content{
            display: flex;
        }
    }
}

// Stencil header
ps-header{
    &:not(.hydrated){
        display: block;
        min-height: $header-height-xl;
        
        @include max-xl{
            min-height: $header-height-lg;
        }
        
        @include max-lg{
            min-height: $header-height-md;
        }
        
        @include max-md{
            min-height: $header-height-sm;
        }
    }
}