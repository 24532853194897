.main-footer{
    padding: 0 0 112px;

    @include max-lg {
        padding: 80px 0;
    }

    &__header {
        display: flex;
        gap: 48px;
        margin-bottom: 80px;

        @include max-lg {
            flex-direction: column;
            margin-bottom: 48px;
            gap: 24px;
        }

        a {
            flex-shrink: 0;
            width: 100px;

            &:focus-visible {
                outline: 2px solid $moss-green;
                outline-offset: 10px;
            }

            img {
                width: 100%;
                display: block;
            }
        }

        p {
            max-width: 500px;
            @extend .body-text;

            @include max-lg {
                max-width: 74%;
            }

            @include max-sm {
                max-width: 100%;
            }

            a {
                text-decoration: underline;
            }
        }
    }

    &__links {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include max-md {
            flex-direction: column;
            gap: 36px;
        }

        .links {
            display: flex;
            flex-direction: column;
            gap: 16px;

            @include max-xl {
                .btn--tertiary {
                    padding-block: 0;
                }
            }

            @include max-lg {
                .btn--tertiary {
                    span {
                        text-decoration-thickness: 1px;
                    }
                }
            }

            @include max-md {
                .btn--tertiary:not([id="englishTranslationFooter"]) {
                    .icon {
                        display: none;
                    }
                }
            }
        }

        &--bottom {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            gap: 40px;
            justify-content: space-between;
            margin-top: 80px;

            @include max-lg {
                margin-top: 36px;
            }

            @include max-md {
                flex-direction: column;
                align-items: flex-start;
            }

            .links-wrapper {
                display: flex;
                flex-direction: column;
                gap: 80px;

                @include max-md {
                    flex-direction: column-reverse;
                    gap: 36px;
                }


                .links {
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 24px;

                    @include max-md {
                        flex-direction: column;
                        gap: 16px;
                    }
                }

                .social {
                    display: flex;
                    flex-direction: row;
                    gap: 32px;

                    a {
                        display: block;

                        &:focus-visible {
                            outline: 2px solid $moss-green;
                            outline-offset: 8px;
                        }

                        img {
                            display: block;
                        }
                    }
                }

            }
        }
    }

    &__copyright {
        flex-shrink: 0;
        @extend .body-text;
    }
}