// Aspect-ratio
.ar{
    display: block;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &--500-529 {
        aspect-ratio: 500/529;
    }
    
    &--16-10{
        aspect-ratio: 16/10;
    }
    
    &--16-9{
        aspect-ratio: 16/9;
    }

    &--4-3{
        aspect-ratio: 4/3;
    }
    
    &--3-4{
        aspect-ratio: 3/4;
    }
    
    &--1-1{
        aspect-ratio: 1/1;
    }
}
