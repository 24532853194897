.newsletter{
    padding: 80px 0;

    @include max-lg{
        padding: 40px 0;
    }

    &--light-green{
        .newsletter__wrapper{
            padding: 80px 0;
            border-radius: 0 56px 0 56px;
            background-color: $light-green;
            
            @include max-lg{
                padding: 56px 32px;
                border-radius: 0 40px 0 40px;
            }
        }
    }

    &__title{
        margin-bottom: 32px;
        color: $primary;
        max-width: 560px;
        @extend .title;
        @extend .title--h3;

        @include max-lg{
            margin-bottom: 16px;
            max-width: 100%;
        }
    }

    &__text{
        margin-bottom: 40px;
        max-width: 560px;
        @extend .body-text;

        @include max-lg{
            max-width: 100%;
            margin-bottom: 24px;
        }
    }

    &__input{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 32px;
        margin-bottom: 40px;

        @include max-lg{
            margin-bottom: 24px;
        }
        
        @include max-md{
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
        }

        &:last-child{
            margin-bottom: 0;
        }

        .input-group{
            &:not(.hs-form-field){
                width: 100%;
                max-width: 410px;
                margin-bottom: 0;
    
                @include max-md{
                    max-width: 100%;
                }
    
                input{
                    width: 100%;
                }
            }
        }
    }

    .hubspot-form{
        padding: 0;
        margin-bottom: 40px;
        max-width: 560px;

        &:last-child{
            margin-bottom: 0;
        }

        @include max-lg{
            max-width: 100%;
            margin-bottom: 24px;
        }

        .hbspt-form{
            margin-top: 0;
        }
    }

    &__button{
        flex-shrink: 0;

        .icon{
            display: none;
        }

        &--check{
            .icon{
                display: block;
            }
        }
    }

    &__bottom-text{
        @extend .body-text;
        @extend .body-text--small;
    }
}