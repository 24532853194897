.html-area{
    > *{
        &:first-child{
            margin-top: 0;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }

    > h1, > h2, > h3, > h4, > h5, > h6{
        margin-top: 56px;
        color: $black;

        @include max-lg{
            margin-top: 40px;
        }
    }

    > h1{
        margin-bottom: 24px;
        @extend .title;
        @extend .title--h1;

        @include max-lg{
            margin-bottom: 16px;
        }
    }
    
    > h2{
        margin-bottom: 24px;
        @extend .title;
        @extend .title--h2;
        
        @include max-lg{
            margin-bottom: 16px;
        }
    }
    
    > h3{
        margin-bottom: 16px;
        @extend .title;
        @extend .title--h3;

        @include max-lg{
            margin-bottom: 8px;
        }
    }

    > h4{
        margin-bottom: 8px;
        @extend .title;
        @extend .title--h4;
    }

    > h5{
        margin-bottom: 8px;
        @extend .title;
        @extend .title--h5;
    }

    > h6{
        margin-bottom: 8px;
        @extend .title;
        @extend .title--h6;
    }

    > p{
        margin-bottom: 30px;
        hyphens: none;
        @extend .body-text;

        @include max-lg{
            margin-bottom: 24px;
        }
    }

    ul, ol{
        margin-bottom: 26px;

        &:last-child{
            margin-bottom: 0;
        }

        @include max-lg{
            margin-bottom: 22px;
        }

        li{
            @extend .body-text;
        }
    }

    ul:not(.share-macro__list){
        list-style: none;
        margin-left: 0;
        padding-left: 30px;

        @include max-lg{
            padding-left: 15px;
        }

        li{
            position: relative;
            padding-left: 28px;

            @include max-lg{
                padding-left: 24px;
            }

            &::before{
                content: "";
                width: 9px;
                height: 9px;
                position: absolute;
                left: 0;
                top: 10px;
                background-color: $black;
                border-radius: 100%;

                @include max-lg{
                    top: 7px;
                }
            }
        }
    }

    ol{
        list-style: decimal;
        padding-left: 48px;

        @include max-lg{
            padding-left: 32px;
        }

        li{
            padding-left: 10px;
    
            @include max-lg{
                padding-left: 8px;
            }
        }
    }

    >figure {
        margin-bottom: 56px;

        @include max-lg {
            width: 100% !important;
            margin-bottom: 40px;
        }

        img {
            display: block;
            width: 100%;
        }

        &.editor-align-right,
        &.editor-align-left {
            margin-top: 0;
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }

            figcaption {
                &:empty {
                    display: none;
                }

                @include max-lg {
                    padding: 0;
                }
            }
        }

        &.editor-align-right {
            margin-left: 32px;

            @include max-lg {
                float: none !important;
                margin-right: 0;
                margin-left: 0;
            }
        }

        &.editor-align-left {
            margin-right: 32px;

            @include max-lg {
                float: none !important;
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    a {
        &:not(.btn):not(.arrow-link):not(.share-macro__link) {
            @include link-text;
        }
    }

    table, .bt-wrapper{
        width: 100%;
        margin-bottom: 56px;
        @extend .body-text;

        @include max-lg{
            margin-bottom: 40px;
        }

        th, td{
            border: 2px solid $black;
            padding: 8px;
            min-width: 120px; // Temporary fix to prevent bad readability of prices
        }

        th{
            word-break: initial;
            @extend .body-text;
            @extend .body-text--bold;
        }

        &.active{
            max-height: initial;

            table{
                width: 100%;
                margin-bottom: 0;
        
                tr{
                    border-bottom: 5px solid transparent;

                    td{
                        border-width: 1px;
                        border-bottom-width: 0;

                        &:last-child{
                            border-block-width: 1px;
                        }
                    }
        
                    th, td{
                        padding: 0px;
                        
                        &:before{
                            padding: 8px;
                            font-weight: $fw-medium;
                            border-right: 1px solid $black;
                        }
                        
                        .bt-content{
                            padding: 8px 8px 8px 16px;
                        }
                    }
                }
            }
        }

        ul, ol{
            padding-left: 0;
        }        
    }
    
    .alert-banner{
        padding: 0;
        margin: 40px 0;
    }
}