.accordion {
    $accordion-colors: (
        green: $green-40,
        yellow: $yellow-40,
        blue: $blue-40,
        red: $red-40,
    );

    @each $color-name, $color in $accordion-colors {
        &--#{$color-name} {
            --accent-color: #{$color};
        }
    }
    
    &__toggle-all {
        margin-bottom: 24px;

        @include max-lg {
            margin-bottom: 26px;
        }

        span {
            display: none;
        }

        &--opened {
            .close-all-text {
                display: block;
            }
        }

        &--closed {
            .open-all-text {
                display: block;
            }
        }
    }

    .accordion-item {
        &:last-child{
            border-bottom: 2px solid $black;
        }

        .accordion-button {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 24px 8px;
            border-top: 2px solid $black;
            text-align: left;
            @extend .ingress;

            @include max-lg{
                padding: 16px 8px;
            }

            .icon {
                width: 32px;
                height: 32px;
                flex-shrink: 0;
                margin-top: 3px;
                margin-right: 16px;

                @include max-lg {
                    width: 24px;
                    height: 24px;
                }
            }

            &[aria-expanded="true"] {
                background-color: var(--accent-color);

                .icon {
                    &--add {
                        display: none;
                    }
                }
            }

            &:not([aria-expanded="true"]) {
                @include min-lg{
                    &:hover, &:focus-visible{
                        &::after{
                            content: "";
                            display: block;
                            position: absolute;
                            width: 100%;
                            bottom: -2px;
                            left: 0;
                            height: 2px;
                            background-color: $black;
                            pointer-events: none;
                            z-index: 1;
                        }
                    }
                }

                .icon {
                    &--subtract {
                        display: none;
                    }
                }
            }

            @include min-lg{
                &:hover, &:focus-visible{
                    background-color: $black;
                    border-color: $black;
                    color: $white;
                    outline: none;
    
                    .icon{
                        &--add{
                            path{
                                fill: $white;
                            }
                        }
    
                        &--subtract{
                            path{
                                stroke: $white;
                            }
                        }
                    }
                }
            }
        }

        .accordion-collapse{
            .accordion-body{
                padding: 32px 110px 56px 56px;
                background-color: var(--accent-color);

                @include max-lg{
                    padding: 16px 24px 32px 48px;
                }

                &--program{
                    padding-right: 40px;

                    ul{
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
            
                        @include max-lg{
                            gap: 16px;
                        }
            
                        li{
                            display: flex;
                            flex-direction: row;
                            gap: 8px;
                            border-bottom: 1px solid $black;
                            padding-bottom: 24px;
            
                            &:last-child{
                                padding-bottom: 0;
                                border: none;
                            }
                            
                            @include max-lg{
                                flex-direction: column;
                                padding-bottom: 16px;
                            }
                
                            p{
                                @extend .body-text;
                            }
                
                            .timestamp{
                                min-width: 165px;
                                color: $black
                            }
                        }
                    }
                }

                &--speaker{
                    padding-right: 24px;
                }
            }
        }

        & + .accordion-item{
            
        }
    }

    &__program-title{
        margin-bottom: 24px;
        color: $black;
        @extend .title;
        @extend .title--h2;
    }
}