.link-macro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    margin-bottom: 30px;

    @include max-lg {
        row-gap: 12px;
        margin-bottom: 24px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &--inline{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        column-gap: 32px;
        row-gap: 32px;

        &:not(:first-child){
            margin-top: 80px;

            @include max-lg{
                margin-top: 40px;
            }
        }

        &:not(:last-child){
            margin-bottom: 80px;

            @include max-lg{
                margin-bottom: 40px;
            }
        }

        @include max-lg{
            row-gap: 12px;
        }
    }
}