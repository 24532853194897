.infobox{
    margin: 80px 0;
    
    &:first-child{
        margin-top: 0;
    }
    
    &:last-child{
        margin-bottom: 0;
    }

    @include max-lg{
        margin: 40px 0;
    }

    &__content{
        padding: 60px;
        border-radius: 24px;

        @include max-lg{
            padding: 30px;
        }

        &.html-area{
            & > * {
                margin: 0 0 4px 0;
        
                &:last-child{
                    margin: 0;
                }
            }

            & > br {
                @include max-md {
                    margin-block: -4px;
                }
            }

            h1, h2, h3, h4, h5, h6{
                color: $black;
            }

            p{
                padding-bottom: 8px;
                strong, b{
                    color: $black;
                }
            }

            p:last-of-type{
                padding-bottom: 0;
                margin-bottom: 0;
            }
        
            ul, ol{
                li{
                    margin-bottom: 8px;
        
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }

        $colors : (
            green: $green-20,
            yellow: $yellow-20,
            blue: $blue-20,
        );

        @each $color-name, $color-value in $colors {
            .infobox--#{$color-name} & {
                background-color: $color-value;
            }
        }
    }
}