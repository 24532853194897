.icon{
    path{
        fill: $black;
    }
}

img{
    &.icon{
        visibility: hidden;
    }
}