.link-list {
    &__item {
        &:last-child{
            .link-list__link{
                border-bottom: 2px solid $black;
            }
        }
    }

    &__link {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 8px;
        padding: 24px 8px 24px 48px;
        color: $black;
        border-top: 2px solid $black;
        @extend .ingress;

        @include max-lg{
            padding-left: 42px;
            padding-block: 20px;
        }

        &:hover, &:focus-visible {
            background-color: $black;
            border-color: $black;
            color: $white;
            outline: none;

            .icon {
                path {
                    fill: $white;
                }
            }
        }

        .icon {
            position: absolute;
            left: 8px;
            flex-shrink: 0;
            margin-top: 6px;
            width: 24px;
            height: 24px;

            @include max-lg{
                width: 18px;
                height: 18px;
            }

            path{
                fill: $black;
            }
        }
    }

    &__description{
        width: 100%;
        @extend .body-text;
    }
}