.patentretningslinjer-document{
    padding: 40px 0;

    &__header{
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-bottom: 80px;
        padding-top: 40px;
        @extend .col-lg-6;
    }

    &__title{
        color: $dark-green;
        @extend .title;
        @extend .title--h1;
    }

    &__ingress{
        hyphens: none;
        @extend .ingress;
    }

    &__content{
        & > * {
            max-width: 100%;
        }

        a{
            &:not([href]){
                padding: 0 !important;
                text-decoration: none !important;
    
                &:hover{
                    background-color: initial !important;
                    color: $primary !important;
                    cursor: text;
                }
            }
        }
    }

}

// ------------------
// WordSection1 custom style
// ------------------
.WordSection1{
    table{
        width: 100%;

        h2, h3, h4, h5, h6{
            &:first-child, &:last-child{
                margin: 0;
            }
        }
    }
}

.WordSection1 .red {
    color: red;
}

.MsoToc1 {
    margin: 0
}

.MsoToc2 {
    margin: 0 0 4px 1.25rem
}

.MsoToc3 {
    margin: 0 0 4px 2.5rem
}

.MsoToc4 {
    margin: 0 0 4px 3.75rem
}

.MsoToc5 {
    margin: 0 0 4px 5rem
}

.MsoToc6 {
    margin: 0 0 4px 6.25rem
}

.MsoToc7 {
    margin: 0 0 4px 7.5rem
}

.WordSection1 h3,.WordSection1 h4,.WordSection1 h5,.WordSection1 h6 {
    margin: 1.5em 0 .5em 0
}

.WordSection1 h3 a,.WordSection1 h4 a,.WordSection1 h5 a,.WordSection1 h6 a {
    text-decoration: none;
    color: #000;
    cursor: default
}

.MsoNormal{
	u{
		text-decoration: initial !important;
	}
}

@media print {
    div.WordSection1 h3 a,div.WordSection1 h4 a,div.WordSection1 h5 a,div.WordSection1 h6 a,div.WordSection1 h3,div.WordSection1 h4,div.WordSection1 h5,div.WordSection1 h6 {
        color: #000!important
    }
}
