.transport-cards {
    padding: 80px 0;

    @include max-lg {
        padding: 40px 0;
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 40px;

        @include max-lg {
            gap: 16px;
        }

        @include max-md {
            flex-direction: column;
        }

        &:not(:last-child) {
            margin-bottom: 40px;

            @include max-lg {
                margin-bottom: 16px;
            }
        }
    }

    &__card {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        padding: 56px 40px 40px;
        border: 2px solid $black;
        border-radius: 24px;

        @include max-lg {
            padding: 32px;
        }

        // two siblings
        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(2) ~ &.transport-cards__card {
            @include min-md {
                width: 50%;
            }
        }

        // three siblings
        &:first-child:nth-last-child(3),
        &:first-child:nth-last-child(3) ~ &.transport-cards__card {
            @include min-md {
                width: 33.33%;
            }
        }

        &:hover,
        &:focus-within {
            .transport-cards__card-arrow {
                transform: translateX(8px);
            }
        }

        &.hover-green {
            &:hover {
                background-color: $green-20;
            }
        }

        &.hover-yellow {
            &:hover {
                background-color: $yellow-20;
            }
        }

        &.hover-blue {
            &:hover {
                background-color: $blue-20;
            }
        }

        &.hover-red {
            &:hover {
                background-color: $red-20;
            }
        }

        &--external {
            &:hover,
            &:focus-within {
                .transport-cards__card-arrow {
                    transform: translateX(0px);
                }
            }
        }
    }

    &__card-link {
        display: block;
        position: absolute;
        inset: 3px;
        border: none;
        border-radius: 24px;

        &:focus {
            outline: none !important;
            border: none;
        }

        &:not(:focus-visible) {
            outline: none !important;
            border: none;
        }

        &:focus-visible {
            outline: none !important;
            border: 2px solid $black;
        }
    }

    &__card-icon {
        display: block;
        width: 48px;
        height: 48px;
    }

    &__card-title {
        @extend .title;
        @extend .title--h3;

        @include max-lg {
            font-size: 22px;
            line-height: 1.3;
        }
    }

    &__card-ingress {
        @extend .body-text;
    }

    &__card-arrow {
        transition: transform 0.2s;

        path {
            fill: $primary;
        }
    }
}
