.event-info{
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 32px;
    margin-bottom: 32px;
    border: 2px solid $cool-green;

    @include max-lg{
        margin-bottom: 16px;
    }

    &:last-child{
        margin-bottom: 0;
    }

    @include max-lg{
        gap: 24px;
    }

    @include max-lg{
        padding: 24px;
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__item{
        display: flex;
        flex-direction: row;
        gap: 8px;
        @extend .body-text;

        &--block{
            flex-direction: column;
        }

        a{
            @include link-text;

            &:not(:hover):not(:focus-visible){
                color: $black;
            }
        }
    }

    &__value{
        display: flex;
        flex-wrap: wrap;
        column-gap: 5px;
    }
    
    &__label{
        flex-shrink: 0;
        @extend .body-text--bold;
    }

    &__status{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    &__tag{
        padding: 4px 8px;
        @extend .body-text;
    }

    &__status-text{
        @extend .body-text;
        @extend .body-text--small;
    }

    .button-group{
        .btn--primary{
            padding-right: 28px;
            padding-left: 28px;
            text-align: center;
        }
    }
}