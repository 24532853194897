// Background
$color-bg: #f2f2e8;

// Primary
$moss-green: #00342e;
$green: #008760;
$sprout-green: #cef261;
$ice-blue: #5ae7ed;

// Green
$green-80: #427560;
$green-60: #73b097;
$green-40: #aed1c3;
$green-20: #c1dcd1;

// Yellow
$yellow-80: #8f6514;
$yellow-60: #eabf6c;
$yellow-40: #f3daaa;
$yellow-20: #f6e2bc;

// Blue
$blue-80: #3b758c;
$blue-60: #6ca9c1;
$blue-40: #c8dfe8;
$blue-20: #d7e8ee;

// Red
$red-80: #af4f46;
$red-60: #f9aca5;
$red-40: #f8d6d2;
$red-20: #f4e2df;

// Secondary
$dark-green: #004934;
$cool-green: #009e6e;
$light-green: #d9ffe3;
$dark-blue-green: #003c46;
$blue-green: #088d9b;
$light-blue-green: #b1f2e6;
$dark-blue: #00234b;
$blue: #257fbb;
$light-blue: #aee5ff;

// Tertiary
$dark-purple: #311255;
$purple: #8b71a8;
$light-purple: #dfd7f9;
$dark-red: #520033;
$red: #b55c75;
$light-red: #f5cccb;
$dark-rust: #630608;
$rust: #cf4c3b;
$light-rust: #ffd6ac;

// Special
$gold: #8c7e37;

// Neutral
$black: #212121;
$gray-80: #333333;
$gray-60: #666666;
$gray-50: #7f807f;
$gray-40: #999999;
$gray-20: #cccccc;
$gray-5: #f2f2f2;
$white: #ffffff;

$primary: $moss-green;

// color list
$colors: (
  color-bg: $color-bg,
  moss-green: $moss-green,
  green: $green,
  sprout-green: $sprout-green,
  ice-blue: $ice-blue,
  dark-green: $dark-green,
  cool-green: $cool-green,
  light-green: $light-green,
  dark-blue-green: $dark-blue-green,
  blue-green: $blue-green,
  light-blue-green: $light-blue-green,
  dark-blue: $dark-blue,
  blue: $blue,
  light-blue: $light-blue,
  dark-purple: $dark-purple,
  purple: $purple,
  light-purple: $light-purple,
  dark-red: $dark-red,
  red: $red,
  light-red: $light-red,
  dark-rust: $dark-rust,
  rust: $rust,
  light-rust: $light-rust,
  gold: $gold,
  black: $black,
  gray-80: $gray-80,
  gray-60: $gray-60,
  gray-50: $gray-50,
  gray-40: $gray-40,
  gray-20: $gray-20,
  gray-5: $gray-5,
  white: $white,
  green-80: $green-80,
  green-60: $green-60,
  green-40: $green-40,
  green-20: $green-20,
  yellow-80: $yellow-80,
  yellow-60: $yellow-60,
  yellow-40: $yellow-40,
  yellow-20: $yellow-20,
  blue-80: $blue-80,
  blue-60: $blue-60,
  blue-40: $blue-40,
  blue-20: $blue-20,
  red-80: $red-80,
  red-60: $red-60,
  red-40: $red-40,
  red-20: $red-20,
);
