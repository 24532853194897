.video-block{
    &--no-image{
        .video-block__play{
            display: none;
        }

        .video-block__poster{
            display: none;
        }
    }

    &--part{
        padding: 80px 0;

        @include max-lg{
            padding: 40px 0;
        }    
    }

    &--macro{
        margin-top: 40px;
        margin-bottom: 40px;

        // Offgrid style
        margin-left: -17.8%;
        
        @include min-xxl{
            width: calc(100% + 220px);
        }

        @include max-xxl{
            width: 135.5%;
        }

        @include max-xl{
            margin-left: -13%;
            width: 126%;
        }

        @include max-lg{
            width: 100%;
            margin-left: 0;
        }
    }

    .wrapper{
        position: relative;
    }

    &__poster{
        width: 100%;
        height: 100%;
    }

    &__video{
        position: relative;
        display: flex;
        background-color: $gray-20;
        
        iframe, video{
            display: none;
            width: 100%;
        }
    }

    &__play{
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0;
        top: 0;
        left: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;
        z-index: 4;

        &:focus-visible{
            outline: 2px solid $primary;
            outline-offset: 7px;
        }

        svg, img{
            width: 96px;
            height: 96px;
            background: $moss-green;

            @include max-lg{
                width: 48px;
                height: 48px;
            }

            path{
                fill: $white;
            }
        }

        img{
            opacity: 0; // prevent wrong style before svg injection
        }
    }

    &__caption{
        margin-top: 16px;
        hyphens: none;
        @extend .body-text;
        @extend .body-text--small;
    
        @include max-lg{
            margin-top: 8px;
        }
    }

    &.active{
        .video-block__video{
            background-color: $gray-40;
        }

        .video-block__play{
            display: none;
        }

        .video-block__video{
            &::before{
                display: none;
            }

            &.ar--16-9{
                @supports (-webkit-touch-callout: none) {
                    /* CSS specific to iOS devices */
                    padding-top: 56.25%;
    
                    iframe, video{
                        position: absolute;
                        top: 0;
                    }
                }
            }

            iframe, video{
                display: block;
                height: 100%;
            }
        }

        .video-block__poster{
            display: none;
        }
    }
}
