.nudging-section{
    padding: 80px 0;

    @include max-lg{
        padding: 40px 0;
    }

    &--light{
        .nudging-section__link{
            @extend .btn--secondary;
        }
    }

    &--dark{
        .nudging-section__wrapper{
            background-color: $dark-green;
        }

        .nudging-section__title{
            color: $sprout-green;
        }

        .nudging-section__text{
            color: $white;
        }

        .nudging-section__link{
            @extend .btn--primary;
            @extend .btn--primary;
        }
    }

    &__wrapper{
        background-color: $light-green;
        padding: 80px 0;

        @include max-lg{
            padding: 48px calc(48px - 16px);
        }
    }

    &__title{
        margin-bottom: 24px;
        color: $primary;
        @extend .title;
        @extend .title--h3;

        @include max-lg{
            margin-bottom: 16px;
        }
    }

    &__text{
        @extend .body-text;
    }

    &__link{
        margin-top: 32px;
        @extend .btn;

        @include max-lg{
            margin-top: 24px;
        }
    }
}