.table-of-contents{
    padding: 32px;
    background-color: $blue-40;
    max-width: 510px;

    &--macro{
        margin: 56px 0;

        @include max-lg{
            margin: 32px 0;
        }

        ol{
            list-style: none;
            padding-left: 0;

            li{
                padding-left: 0;
            }
        }
    }
    
    .title{
        margin-bottom: 16px;
        color: $black;
    }

    ol{
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include max-lg{
            gap: 2px;
        }

        li.arrow-link{
            margin-bottom: 0;
            
            a{
                @include max-lg{
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
        }
    }
}