.arrow-link{
    margin-bottom: 32px;

    &:last-child{
        margin-bottom: 0;
    }

    a, button{
        display: inline-flex;
        align-items: flex-start;
        gap: 8px;
        padding: 0px 2px;
        @include link-text;
        @extend .body-text;

        @include max-lg{
            padding-top: 10px;
            padding-bottom: 10px;
        }
    
        .icon{
            flex-shrink: 0;
            margin-top: 6px;
            width: 18px;
            height: 18px;

            @include max-lg{
                margin-top: 4px;
            }
        }
    }

    &--dark{
        a, button{
            @include link-text(dark);
        }
    }
}