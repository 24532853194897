.event-layout{
    &__container{
        padding-top: 80px;

        @include max-lg{
            padding-top: 40px;
        }

        & > .row{
            @include max-lg{
                gap: 80px;
            }
        }
    }

    &__intro{
        margin-bottom: 40px;
        @extend .ingress;
    }

    &__content{
        & > .html-area{
            margin-bottom: 120px;

            @include max-lg{
                margin-bottom: 80px;
            }

            .video-block--macro{
                width: auto;
                margin-left: 0;
            }
        }

        // Offgrid, 1 column
        & > .html-area .alert-banner,
        & > .html-area .video-block--macro,
        .event-layout__accordion{
            margin-right: -18%;

            @include max-xl{
                margin-right: -15.5%;
            }

            @include max-lg{
                margin-right: 0;
            }
        }

        .event-layout__accordion,
        .contact-card{
            padding: 0;
            margin-top: 120px;
            margin-bottom: 120px;
    
            @include max-lg{
                margin-top: 80px;
                margin-bottom: 80px;
            }
        }

        & > * {
            &:first-child{
                margin-top: 0;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    &__accordion-title{
        margin-bottom: 24px;
        color: $primary;
        @extend .title;
        @extend .title--h2;
    }

    &__aside{
        @include max-lg{
            order: -1;
        }
    }
}