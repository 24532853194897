$grid-columns: 12;
$grid-row-columns: 6;
$grid-gutter-width: 40px;
$grid-gutter-width-sm: 16px;

// Screen sizes
//!
$screen-xs: 425px; // Extra small
$screen-sm: 600px; // Small
$screen-md: 768px; // Medium
$screen-lg: 1024px; // Large
$screen-xl: 1240px; // Extra large
$screen-xxl: 1440px; // Extra Larger

$container-gutter: 80px;
$container-gutter-sm: 16px;

$grid-breakpoints: (
   xxs: 0px,
   xs: $screen-xs,
   sm: $screen-sm,
   md: $screen-md,
   lg: $screen-lg,
   xl: $screen-xl,
   xxl: $screen-xxl
) !default;

$container-max-widths: (
   xs: 100%,
   // Extra small
   sm: 100%,
   // Small
   md: 100%,
   // Medium
   lg: 100%,
   // Large
   xl: 100%,
   // Extra large
   xxl: 1440px // Extra larger
) !default;

// media queries mixins
@mixin min-xs {
   @media (min-width: $screen-xs) {
      @content;
   }
}

@mixin max-xs {
   @media (max-width: ($screen-xs - 1)) {
      @content;
   }
}

@mixin min-sm {
   @media (min-width: $screen-sm) {
      @content;
   }
}

@mixin max-sm {
   @media (max-width: ($screen-sm - 1)) {
      @content;
   }
}


@mixin min-md {
   @media (min-width: $screen-md) {
      @content;
   }
}

@mixin max-md {
   @media (max-width: ($screen-md - 1)) {
      @content;
   }
}


@mixin min-lg {
   @media (min-width: $screen-lg) {
      @content;
   }
}

@mixin max-lg {
   @media (max-width: ($screen-lg - 1)) {
      @content;
   }
}

@mixin min-xl {
   @media (min-width: $screen-xl) {
      @content;
   }
}

@mixin max-xl {
   @media (max-width: ($screen-xl - 1)) {
      @content;
   }
}


@mixin min-xxl {
   @media (min-width: $screen-xxl) {
      @content;
   }
}

@mixin max-xxl {
   @media (max-width: ($screen-xxl - 1)) {
      @content;
   }
}


.container {
   --custom-gutter: #{$grid-gutter-width};
   
   @include max-lg {
      --custom-gutter: #{$grid-gutter-width-sm};
   }

   padding-right: $container-gutter !important;
   padding-left: $container-gutter !important;
   
   @include max-lg {
      padding-right: $container-gutter-sm !important;
      padding-left: $container-gutter-sm !important;
   }

   .row {
      margin-right: calc(var(--custom-gutter) * -.5) !important;
      margin-left: calc(var(--custom-gutter) * -.5) !important;

      & > * {
         padding-right: calc(var(--custom-gutter) * .5) !important;
         padding-left: calc(var(--custom-gutter) * .5) !important;
      }
   }
}