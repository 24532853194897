.pagination{
    padding: 40px 0 80px;

    @include max-lg{
        padding: 40px 0;
    }

    &--search{
        .pagination__col{
            @extend .col-xl-8;
            @extend .offset-xl-3;
            @extend .col-md-10;
            @extend .offset-md-1;
        }

        .pagination__list, .pagination__load-more{
            justify-content: flex-start;
        }
    }

    &__col{
        @extend .col-12;
    }

    &__list{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;

        @include max-md{
            display: none;
        }
    }

    &__item{
        &--prev{
            margin-right: 8px;
        }

        &--next{
            margin-left: 8px;
        }
    }

    &__btn{
        padding-right: 24px;
        padding-left: 24px;
        white-space: nowrap;

        .icon{
            width: 24px;
            height: 24px;
        }

        &--number{
            flex-wrap: wrap;
            max-width: 50px;
            max-height: 50px;
            padding-right: 23px;
            padding-left: 23px;
            justify-content: center;
            white-space: nowrap;
        }

        &[aria-current="page"]{
            background-color: $black;
            color: $white;
        }

        &:hover, &:focus-visible {
            &:not([aria-current="page"]) {
                background-color: $green-20;
                color: $black;
            }
        }
    }

    &__load-more{
        display: none;

        @include max-md{
            display: flex;
            justify-content: center;
        }
    }
}