.media-grid{
    padding: 40px 0;

    &__header{
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 40px;

        @include max-lg{
            margin-bottom: 24px;
        }
    }

    &__title{
        @extend .title;
        @extend .title--h3;
    }

    &__intro{
        @extend .body-text;
    }

    &__grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: $grid-gutter-width;
        grid-row-gap: $grid-gutter-width;
        
        @include max-xl{
            grid-template-columns: repeat(3, 1fr);
        }
        
        @include max-lg{
            grid-column-gap: $grid-gutter-width-sm;
        }
        
        @include max-md{
            grid-template-columns: repeat(2, 1fr);
        }
        
        @include max-sm{
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__card{
        position: relative;

        & > *{
            margin-bottom: 16px;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    &__figure{
        width: 100%;
        padding: 24px;
        border: 2px solid $green;
        
        img{
            display: block;
            width: 100%;
        }
    }
    
    &__card-title{
        color: $primary;
        @extend .title;
        @extend .title--h3;
    }
    
    &__card-text{
        @extend .body-text;
    }
}