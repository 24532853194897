.quote{
    padding: 80px 0;
    @extend .offgrid-macro;
    
    @include max-lg{
        padding: 40px 0;
    }

    // Image variation
    &--image{
        .quote__wrapper{
            @include min-lg{
                padding: 80px 0;
            }

            .row{
                flex-direction: row;

                @include max-md{
                    flex-direction: column-reverse;
                }
            }
        }

        .quote__text{
            @extend .col-xl-6;
            @extend .offset-xl-1;
            @extend .col-lg-7;
            @extend .offset-lg-1;
            @extend .col-md-7;

            p{
                max-width: 560px
            }
        }

        .quote__author{
            @extend .col-xl-4;
            @extend .offset-xl-0;
            @extend .col-lg-3;
            @extend .offset-lg-0;
            @extend .col-md-4;
            @extend .offset-md-1;

            @include min-lg{
                padding-left: 50px !important;
            }
        }

        // "Reverse" variation
        &.quote--reverse{
            .quote__wrapper{
                .row{
                    @include min-md{
                        flex-direction: row-reverse;
                        justify-content: flex-end;
                    }
                }
            }

            .quote__author{
                @extend .offset-lg-1;
                @extend .offset-md-0;

                @include min-lg{
                    padding-left: 20px !important;
                }
            }

            .quote__text{
                @extend .offset-xl-0;
                @extend .offset-lg-0;
                @extend .offset-md-1;
            }
        }

        &.quote--short{
            .quote__author-name{
                margin-top: 32px;

                @include max-md{
                    display: none;
                }
            }

            .quote__author-position{
                @include max-md{
                    display: none;
                }
            }

            .quote__figure{
                @include min-md{
                    margin: 0;
                }
            }

            .quote__author{
                .quote__author-name{
                    display: none;

                    @include max-md{
                        display: block;
                    }
                }

                .quote__author-position{
                    display: none;

                    @include max-md{
                        display: block;
                    }
                }
            }

            // Short + reverse
            &.quote--reverse{
                .quote__author{
                    @extend .col-lg-3;
                }
            }
        }
    }
    
    &__wrapper{
        padding: 56px 0;
        border-radius: 32px;

        $colors: (
            green: $green-20,
            yellow: $yellow-20,
            blue: $blue-20,
        );

        @each $color-name, $color-value in $colors {
            .quote--#{$color-name} & {
                background-color: $color-value;
            }
        }
        
        @include max-lg{
            padding: 56px 32px;
            border-radius: 24px;
        }
        
        .row{
            display: flex;
            flex-direction: column;
            row-gap: 32px;
            
            @include max-lg{
                row-gap: 56px;
            }
        }
    }

    &__text{
        @extend .col-xl-7;
        @extend .offset-xl-2;
        @extend .col-lg-9;
        @extend .offset-lg-1;
        
        p{
            max-width: 700px;

            &:not(.quote__author-position){
                @extend .body-text;
            }
        }
    }

    &__author{
        @extend .col-xl-7;
        @extend .offset-xl-2;
        @extend .col-lg-9;
        @extend .offset-lg-1;
    }

    &__figure{
        width: 160px;
        height: 160px;
        margin-bottom: 32px;

        img{
            border-radius: 100%;
        }
    }

    &__author-name{
        max-width: 290px;
        color: $black;
        margin: 0;
        @extend .body-text;
        @extend .body-text--bold;
    }
    
    &__author-position{
        max-width: 290px;
        color: $black;
        margin: 0;
        @extend .body-text;
        @extend .body-text--small;
    }

    // Quote macro on Event pages (PAT-160)
    .event-layout &{
        width: 100%;
        margin: 56px 0;
        padding: 0;

        &:first-child{
            margin-top: 0;
        }

        &:last-child{
            margin-bottom: 0;
        }

        .quote__wrapper{
            padding: 48px;

            @include max-lg{
                padding: 48px 32px;
            }

            .row{
                row-gap: 48px;
                margin: 0 !important;

                @include max-lg{
                    row-gap: 32px;
                }
            }
        }

        .quote__text{
            padding: 0 !important;
            margin: 0;
            @extend .col-12;
        }

        .quote__author{
            padding: 0 !important;
            margin: 0;
            @extend .col-12;
        }
        
        .quote__author-name, .quote__author-position{
            max-width: 100%;
        }

        &.quote--short{
            .quote__wrapper{
                .row{
                    row-gap: 32px;

                    @include max-lg{
                        row-gap: 24px;
                    }
                }
            }

            .quote__text{
                width: inherit;
                flex: 1;
            }

            &.quote--image{
                .quote__wrapper{
                    .row{
                        column-gap: 48px;

                        @include max-lg{
                            row-gap: 32px;
                        }
                    }
                }

                .quote__author{
                    @include min-lg{
                        width: 160px;
                    }
                }
            }
        }

        // Not short
        &:not(.quote--short){
            &.quote--image{
                .quote__wrapper{
                    .row{
                        flex-direction: column;

                        @include max-lg{
                            flex-direction: column-reverse;
                        }
                    }
                }
                
                .quote__author{
                    @include min-lg{
                        position: relative;
                        margin: 0;
                        padding-right: 0 !important;
                        padding-left: 192px !important;
                        min-height: 160px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
    
                .quote__figure{
                    @include min-lg{
                        position: absolute;
                        left: 0;
                        margin: 0;
                    }
                }
            }

            &.quote--reverse{
                &.quote--image{
                    .quote__wrapper{
                        .row{
                            @include min-lg{
                                flex-direction: column-reverse;
                            }
                        }
                    }
                }
            }
        }
    }
}