.hero{
    padding-bottom: 80px;

    &__content{
        @extend .col-lg-6;
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding-top: 80px;
        @extend .col-lg-6;

        .button-group {
            @include max-md {
                flex-direction: row;
                gap: 16px;
            }
        }
    }

    &__figure{
        @extend .col-lg-5;
        @extend .offset-lg-1;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    &__title{
        color: $black;
        @extend .title;
        @extend .title--h1;
    }

    &__ingress{
        hyphens: none;
        @extend .ingress;
    }

    &--image{
        .hero__content{
            @include max-lg{
                padding-top: 40px;
                order: 3;
            }
        }

        .hero__figure{

            @include max-lg{
                padding-top: 40px;
            }
        }
    }

    &--image-large{
        .hero__content{
            @include max-lg{
                padding-top: 24px;
            }
        }

        .hero__figure{
            @include max-lg {
                padding-top: 40px;
            }
            
            @include min-lg{
                padding-top: 80px;
            }
        }

        .hero__title {
            @extend .hero-text;
        }
    }

    &--video{
        .hero__content{
            @extend .col-lg-8;
            
            @include max-lg{
                padding-top: 24px;
            }
        }

        .hero__figure{
            @extend .col-lg-12;

            @include min-lg{
                padding-top: 80px;
            }

            .video-block{
                @include max-md{
                    margin: 0 -16px;
                    width: 100vw;
                }
            }
        }
    }

    & + .rich-text{
        padding-top: 0;
    }
}