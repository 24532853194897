.test-results{
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding-top: 40px;
    
    @include max-lg{
        padding-top: 40px;
        gap: 40px;
    }

    &:first-child{
        padding-top: 120px;
    }

    // Q&A Accordion
    &__accordion-heading{
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 40px;
        
        @include max-lg{
            gap: 8px;
            margin-bottom: 24px;
        }
    }
    
    &__accordion-title{
        color: $primary;
        @extend .title;
        @extend .title--h3;
    }

    &__accordion-ingress{
        @extend .body-text;
    }

    &__accordion-list{
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 0 !important;

        @include max-lg{
            gap: 24px;
        }
        
        & > li{
            padding: 0 !important;

            &::before{
                display: none;
            }

            h5{
                @extend .body-text;
                font-weight: $fw-bold;

                @include max-lg{
                    font-size: 20px;
                    line-height: 1.5;
                }
            }

            p{
                @extend .body-text;
            }
        }
    }
}