.details-box{
    &[open]{
        .details-box__summary{
            span{
                &.close{
                    display: inline-flex;
                }
    
                &.text{
                    display: none;
                }
            }
        }
    }

    &__summary{
        display: inline-flex;
        padding: 8px;
        background-color: $light-blue;
        list-style-type: none;
        cursor: pointer;
        @extend .body-text;

        &::-webkit-details-marker {
            display:none;
        }

        &:hover, &:focus-visible{
            @include min-lg{
                background-color: $dark-blue;
                color: $white;
                outline: none;
    
                span{
                    .icon{
                        path{
                            fill: $white;
                        }
                    }
                }
            }
        }

        span{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            &.close{
                display: none;
            }
        }
    }

    &__content{
        display: block;
        background-color: $light-blue;
        padding: 8px;
        @extend .body-text;
    }
}