.two-columns {
    padding: 80px 0;

    @include max-lg {
        padding: 40px 0;
    }

    &__heading {
        margin-bottom: 80px;

        @include max-md {
            margin-bottom: 40px;
        }

        & > * {
            @extend .title;
            @extend .title--h2;
            color: $black;

            @include min-md {
                max-width: 74%;
            }

            @include min-lg {
                max-width: calc(50% - 20px);
            }
        }
    }

    &__left {
        .two-columns--single-column & {
            margin-bottom: 24px;

            & > * {
                @include min-md {
                    max-width: 100%;
                }
            }
        }

        @include max-md {
            margin-bottom: 16px;
        }

        & > * {
            @include min-md {
                max-width: 270px;
            }
        }
    }

    &__title {
        color: $black;
        margin-bottom: 16px;
        @extend .title;
        @extend .title--h4;

        .two-columns--single-column & {
            display: inline-block;
            @extend .title--h2;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__intro {
        @extend .body-text;
    }

    &__right {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include max-lg {
            gap: 24px;
        }

        .html-area {
            .table-of-contents--macro {
                &:first-child {
                    @include max-md {
                        margin-top: 24px;
                    }
                }
            }
        }
    }

    &__cta {
        align-self: flex-start;
        margin-top: 10px;

        @include max-md {
            margin-top: 0;
        }
    }

    & + .two-columns {
        padding-top: 40px;
    }
}
