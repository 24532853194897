.registration{
    padding: 80px 0;

    @include max-lg{
        padding: 40px 0;
    }

    &__content{
        display: flex;
        flex-direction: column;
        gap: 80px;
        
        @include min-xl{
            padding: 0 30px;
        }

        @include max-lg{
            gap: 40px;
        }
    }

    &__header{
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include max-lg{
            gap: 16px;
        }

        .button-group{
            margin-top: 56px;

            @include max-lg{
                margin-top: 24px;
            }
        }
    }

    &__section{
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include max-lg{
            gap: 16px;
        }

        &--invite{
            @include max-lg{
                gap: 24px;
            }
        }
        
        .input-group{
            margin-bottom: 0;
        }
    }

    &__title{
        color: $dark-green;
        @extend .title;
        @extend .title--h1;
    }

    &__ingress{
        @extend .ingress;
    }

    &__subtitle{
        color: $dark-green;
        @extend .title;
        @extend .title--h2;
    }

    .html-area{
        h3{
            color: $dark-green;

            @include max-lg{
                margin-bottom: 16px;
            }
        }
    }
}