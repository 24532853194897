.speedometer{
    &__label{
        margin-bottom: 16px;
        @extend .title;
        @extend .title--h5;

        @include max-lg{
            margin-bottom: 8px;
        }
    }

    &--high{
        .speedometer__fill{
            fill: $sprout-green;
        }
    }

    &--average{
        .speedometer__fill{
            fill: $light-rust;
        }
    }

    &--low{
        .speedometer__fill{
            fill: $dark-rust;
        }
    }

    &__score{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 24px;
    }

    &__image{
        flex-shrink: 0;
        width: 160px;

        @include max-lg{
            width: 108px;
            height: 54px;
        }
    }

    &__background{
        fill: $white;
        stroke: $black;
        stroke-width: 2px;
    }
    
    &__fill{
        fill: $gray-20;
        stroke: $black;
        stroke-width: 2px;
    }

    &__text{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
    
    &__title{
        @extend .title;
        @extend .title--h2;
    }

    &__subtitle{
        @extend .title;
        @extend .title--h5;
    }
}