.event-overview{
    &__skip{
        display: none;
        margin-top: -120px;
        margin-bottom: 120px;
        order: -2;
        
        @include max-lg{
            display: flex;
        }
    }

    &__toggler{
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0;

        @include min-lg{
            display: none;
        }
        
        &:focus-visible{
            & + label{
                outline: 2px solid black;
            }
        }

        &:checked{
            & + label{
                margin-top: 40px;

                .show-text{
                    display: none;
                }

                .hide-text{
                    display: block;
                }

                & + .wrapper{
                    display: flex;
                    order: -1;

                    & + .event-overview__reset--active{
                        display: inline-flex;
                    }
                }
            }
        }
        
        & + label{
            margin-bottom: 24px;
            order: 1;

            @include min-lg{
                display: none;
            }

            .hide-text{
                display: none;
            }
        }


    }
    
    &__filter{
        @include max-lg{
            display: flex;
            flex-direction: column;
            padding-top: 80px;
        }

        .wrapper{
            display: flex;
            flex-direction: column;
            gap: 40px;
            padding-right: 80px;
    
            @include max-xxl{
                padding-right: $grid-gutter-width;
            }
    
            @include max-lg{
                padding-right: 0;
                display: none;
            }
    
            .input-group{
                margin-bottom: 0;
    
                & > .input-group__label{
                    font-weight: $fw-medium;
                }
            }
        }
    }

    &__reset{
        display: none;
        margin-top: 40px;

        &--active{
            @include min-lg{
                display: inline-flex;
            }
        }
    }

    &__content{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 56px;
        padding-bottom: 216px;

        @include max-lg{
            gap: 80px;
            padding: 80px 0;
            border-top: 2px solid $cool-green;
        }

        @include min-lg{
            &::before{
                content: "";
                display: block;
                position: absolute;
                left: -$grid-gutter-width;
                height: 100%;
                width: 2px;
                background-color: $cool-green;
            }
        }
    }

    &__header{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__header-title{
        @extend .title;
        @extend .title--h2;
    }

    &__header-numbers{
        @extend .ingress;
    }

    .pagination{
        padding: 0;

        .container{
            padding: 0 !important;   
        }

        &__list{
            justify-content: flex-start;
        }

        &__load-more{
            @include max-md{
                justify-content: flex-start;
            }
        }
    }
}