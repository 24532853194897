.employee-list{
    padding: 80px 0;

    @include max-lg{
        padding: 40px 0;
    }

    &__title{
        margin-bottom: 40px;
        color: $black;
        @extend .title;
        @extend .title--h3;

        @include max-lg{
            margin-bottom: 24px;
        }
    }

    &__item{
        display: flex;
        flex-direction: row;
        gap: 40px;
        padding: 24px 0 72px;
        border-top: 2px solid $black;

        @include max-lg{
            gap: 32px;
            padding: 24px 0 56px;
        }

        @include max-sm{
            flex-direction: column;
        }
    }

    &__item-figure{
        width: 180px;
        flex-shrink: 0;
    }

    &__item-content{
        & > * {
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    &__item-name{
        margin-bottom: 8px;
        color: $black;
        @extend .title;
        @extend .title--h4;
    }

    &__item-role{
        margin-bottom: 24px;
        color: $black;
        @extend .title;
        @extend .title--h5;

        @include max-lg{
            margin-bottom: 16px;
        }
    }

    &__item-description{
        margin-bottom: 24px;

        @include max-lg{
            margin-bottom: 16px;
        }
    }

    &__item-info{
        tr{
            &:last-child{
                td{
                    padding-bottom: 0;
                }
            }
            
            td{
                padding-bottom: 16px;
                display: table-cell;
                @extend .body-text;

                @include max-lg{
                    padding-bottom: 18px;
                }

                &:first-child{
                    white-space: nowrap;
                }

                &:last-child{
                    padding-left: 16px;
                }

                > * {
                    display: inline;
                    vertical-align: middle;
                }

                .icon{
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }

                a{
                    @include link-text;
                    color: $black;
                    hyphens: none;

                    @include max-md{
                        padding-top: 12px;
                        padding-bottom: 13px;
                    }
                }
            }
        }
    }

    & + .employee-list {
        padding-top: 0;
    }
}