.alert-banner {
    padding: 40px 0;

    &:last-child {
        padding-bottom: 0;
    }

    &__icon {
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
    }

    &--information {
        .alert-banner__wrapper {
            background-color: $blue-20;
            border-color: $black;
        }

        .alert-banner__icon {
            background-image: url("./../images/icons/info.svg");
        }
    }

    &--success {
        .alert-banner__wrapper {
            background-color: $green-20;
            border-color: $black;
        }

        .alert-banner__icon {
            background-image: url("./../images/icons/check-circle.svg");
        }
    }

    &--warning {
        .alert-banner__wrapper {
            background-color: $yellow-20;
            border-color: $black;
        }

        .alert-banner__icon {
            background-image: url("./../images/icons/warning-amber.svg");
        }
    }

    &--error {
        .alert-banner__wrapper {
            background-color: $red-60;
            border-color: $black;

            .arrow-link {
                a {
                    &:focus-visible,
                    &:hover {
                        background-color: $black;
                        color: $white;

                        .icon {
                            path {
                                fill: currentColor;
                            }
                        }
                    }
                }
            }
        }

        .alert-banner__icon {
            background-image: url("./../images/icons/warning-filled-black.svg");
        }

        .alert-banner__close {
            &:focus-visible {
                outline: 2px solid $white;
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 24px;
        padding: 32px;
        border: 2px solid $primary;

        @include max-lg {
            padding: 24px;
        }

        & > * {
            flex-shrink: 0;
        }
    }

    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__title {
        @extend .title;
        @extend .title--h6;
    }

    &__text {
        @extend .body-text;
    }

    .arrow-link {
        a {
            &:not(:hover):not(:focus-visible) {
                color: $black;
                text-decoration-color: $black;

                .icon {
                    path {
                        fill: currentColor;
                    }
                }
            }
        }
    }

    &__close {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        @extend .body-text;
        @extend .body-text--small;

        @include max-lg {
            min-width: 44px;
        }

        &:focus-visible {
            outline: 2px solid $primary;
            outline-offset: 8px;
        }

        .icon {
            width: 24px;
            height: 24px;
        }
    }
}
