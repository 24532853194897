.contact-card{
    padding: 80px 0;

    @include max-lg{
        padding: 40px 0;
    }

    &__heading{
        color: $primary;
        @extend .title;
        @extend .title--h3;
        
        @include min-lg{
            max-width: 560px;
        }

        .html-area &{
            margin-top: 0px;
            margin-bottom: 32px;
        }

        & + .contact-card__wrapper{
            margin-top: 32px;
        }

        & + .contact-card__ingress{
            margin-top: 16px;

            @include max-lg{
                margin-top: 8px;
            }
        }
    }

    &__ingress{
        @extend .ingress;

        & + .contact-card__wrapper{
            margin-top: 24px;

            @include max-lg{
                margin-top: 16px;
            }
        }
    }
    
    &__wrapper{
        padding: 32px;
        border: 2px solid $cool-green;
        
        @include max-lg{
            padding: 24px 32px;
        }

        @include min-lg{
            max-width: 560px;
        }
    }

    table.contact-card__table{
        margin-bottom: 0;

        tbody{
            tr{
                &:last-child{
                    padding-bottom: 0;

                    td{
                        padding-bottom: 0;
                    }
                }

                td{
                    padding: 0 0 16px 0;
                    border: none;
                    @extend .body-text;

                    &:first-child{
                        padding-right: 16px;

                        @include max-sm{
                            white-space: nowrap;
                            vertical-align: middle;
                        }
                    }

                    .icon{
                        width: 24px;
                        height: 24px;
                        vertical-align: middle;
                        margin-right: 8px;
                    }

                    a{
                        hyphens: none;
                        @include link-text(black);

                        @include max-sm{
                            display: inline-block;
                            padding-top: 10px !important;
                            padding-bottom: 10px !important;
                        }
                    }
                }
            }
        }
    }

    &__aditional-info{
        margin-top: 16px;

        p{
            margin-bottom: 4px;
            @extend .body-text--small;

            &:last-child{
                margin-bottom: 0;
            }

            a{
                @include link-text(black);
            }
        }
    }

    &__button{
        margin-top: 32px;

        @include max-lg{
            margin-top: 24px;
        }

        .icon{
            @include max-lg{
                display: none;
            }
        }
    }

    &--advanced{
        table.contact-card__table{
            tbody{
                tr{
                    @include max-sm{
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 16px;
                    }

                    td{
                        @include max-sm{
                            padding: 0;
                        }

                        &:first-child{
                            @include min-sm{
                                word-break: initial;
                                word-wrap: initial;
                                hyphens: none;
                            }

                            @include max-sm{
                                white-space: inherit;
                                vertical-align: inherit;
                            }
    
                            @extend .body-text--bold;
                        }
                    }
                }
            }
        }

        .contact-card__wrapper{
            @include max-lg{
                padding: 32px;
            }
        }

        .contact-card__aditional-info{
            margin-top: 32px;

            @include max-lg{
                margin-top: 24px;
            }
        }
    }
}