/*
  text-cotrast 
  bg - background classe and text contrast
*/

$html-font-size: 16px;


@function stripUnit($value) {
    @return $value / ($value * 0 + 1);
}

@function rem($pxValue) {
    @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

@mixin font($family, $weight, $style) {
	font-family: $family, sans-serif;
	font-weight: $weight;
  font-style: $style;
  font-display:swap;
}

@mixin background-image($image, $width, $height) {
  background-image: url("../images/"+$image);
  background-repeat: no-repeat;
  background-position: center center;
  width: $width + px;
  height: $height + px;
  content: "";
  display: inline-block;
  background-size: cover;
}

@mixin gen-props($prefix, $property) {
  @each $color-name, $color in $colors {
    body .#{$prefix}-#{$color-name} {
      #{$property}: $color
    }
  }
}

@include gen-props('text', 'color');
@include gen-props('bg', 'background-color');


/* Adjusted cotrast limit based on figma and check on minimun contrast*/
/* Adjusted cotrast limit based on figma and check on minimun contrast*/
@mixin text-cotrast($background-color) {
  $color-brightness: calc((red($background-color) * 299) + (green($background-color) * 587) + (blue($background-color) * 114) / 1000);
  $light-color: calc((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000);
  
  @if abs($color-brightness) < calc($light-color/2){
    color: $white;
  }

  @else {
    color: $main-text-color;
  }
}

@mixin text-cotrast($background-color) {
  $color-brightness: calc((red($background-color) * 299) + (green($background-color) * 587) + (blue($background-color) * 114) / 1000);
  $light-color: calc((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000);
  
  @if abs($color-brightness) < calc($light-color/2){
    color: $white;
  }

  @else {
    color: $main-text-color;
  }
}