.score{
    &:nth-child(3n + 1){
        --color: #{$primary};
    }
    &:nth-child(3n + 2){
        --color: #{$green};
    }
    &:nth-child(3n + 3){
        --color: #{$sprout-green};
    }

    &__label{
        margin-bottom: 4px;
        @extend .title;
        @extend .title--h5;
    }

    &__progress{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        
        @include max-lg{
            gap: 16px;
        }
        
        progress[value] {
            /* the background color */
            --background: #{$gray-20};
            flex: 1;
            height: 28px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 2px solid $black;
            margin: 0;
            border-radius: 100px;
            background: var(--background);
            overflow: hidden;
        }
    
        // Webkit
        progress[value]::-webkit-progress-bar {
            border-radius: 100px;
            background: var(--background);
        }
        
        // Webkit
        progress[value]::-webkit-progress-value {
            border-radius: 100px 0px 0px 100px;
            background: var(--color);
            box-shadow: 0px 0px 0px 2px $black;
        }
        
        // Mozila
        progress[value]::-moz-progress-bar {
            border-radius: 100px 0px 0px 100px;
            background: var(--color);
            box-shadow: 0px 0px 0px 2px $black;
        }
    }

    &__progress-value{
        flex-shrink: 0;
        padding: 4px 8px;
        min-width: 70px;
        @extend .title;
        @extend .title--h5;
        
        @include max-lg{
            min-width: 52px;
            padding: 4px;
        }
    }
}