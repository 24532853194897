.question{
    padding: 120px 0;

    @include max-lg{
        padding: 40px 0;
    }

    &__wrapper{
        display: flex;
        flex-direction: column;
        gap: 80px;

        @include max-lg{
            gap: 40px;
        }
    }

    &__indication{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__indicator{
        @extend .title;
        @extend .title--h6;
    }

    &__change-method{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        gap: 16px;
    }

    &__method{
        @extend .body-text;
        @extend .body-text--bold;
    }

    &__heading{
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include max-lg{
            gap: 24px;
        }
    }

    &__question{
        color: $primary;
        @extend .title;
        @extend .title--h2;
    }

    &__options{
        margin: 0;
    }

    &__nav{
        display: flex;
        flex-direction: row;
        gap: 32px;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__nav-next{
        margin-left: auto;
    }

    .alert-banner{
        padding: 0;
    }
}