.button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 32px;

    @include max-lg {
        gap: 24px;
    }

    @include max-md {
        flex-direction: column;
    }
}

.btn, .link-macro > a.btn {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 6px 48px;
    border-radius: 999px;
    min-height: 50px;
    outline: none;
    border: 2px solid $black;
    cursor: pointer;
    // flex-shrink: 0;
    @extend .body-text;

    @include max-lg {
        padding: 8px 36px;
    }

    &:focus-visible {
        box-shadow:
            0 0 0 2px $color-bg,
            0 0 0 4px $black;
    }

    &:active {
        @include min-lg {
            box-shadow:
                inset 0 0 0 1px $black,
                0 0 0 1px $black;
            transition: 0.1s;
        }
    }

    &--internal {
        &:hover,
        &:focus-visible {
            .icon {
                @include min-lg {
                    transform: translateX(8px);
                }
            }
        }
    }

    &--back {
        &:hover,
        &:focus-visible {
            .icon {
                @include min-lg {
                    transform: translateX(-8px);
                }
            }
        }

        &::after {
            right: 0;
        }

        .button-group & {
            align-self: flex-start;
        }
    }

    .icon {
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        transition: transform 0.2s;

        &:first-child {
            margin-left: -12px;
        }

        &:last-child {
            margin-right: -12px;
        }
    }

    &--primary {
        background-color: $black;
        color: $white;

        &:hover {
            background-color: $color-bg;
            color: $black;

            .icon {
                path {
                    fill: currentColor;
                }
            }
        }

        &:focus-visible {
            background-color: $black;
            color: $white;
        }

        .icon {
            path {
                fill: currentColor;
            }
        }

        &.btn--special {
            $colors: (
                green: $green-40,
                yellow: $yellow-40,
                blue: $blue-40
            );

            @each $color-name, $color-value in $colors {
                &.special--#{$color-name} {
                    background-color: $color-value;
                }
            }

            color: $black;

            &:hover {
                background-color: $black;
                color: $white;
            }

            .icon {
                path {
                    fill: currentColor;
                }
            }
        }
    }

    &--secondary {
        background-color: transparent;
        color: $black;
        border: 2px solid $black;

        @include min-md {
            &:hover {
                background-color: $black;
                color: $white;
            }

            &:active,
            &:focus-visible {
                background-color: transparent;
                color: $black;
            }
        }

        .icon {
            path {
                fill: currentColor;
            }
        }
    }

    &--tertiary {
        position: relative;
        padding: 0px 2px;
        border-radius: 0;
        min-height: auto;
        @include link-text;
        transition: margin-right 0.2s;
        z-index: 1;
        text-decoration: none;
        border: none;

        span {
            text-decoration: underline;
            text-decoration-thickness: 2px;
        }

        &::before {
            content: "";
            position: absolute;
            display: none;
            inset: -10px 0;

            @include max-md {
                display: block;
            }
        }

        &:hover,
        &:focus-visible {
            @include min-lg {
                color: $white;

                span {
                    text-decoration: none;
                }

                .icon {
                    path {
                        fill: currentColor;
                    }
                }
            }

            &.btn--internal,
            &.btn--back {
                &::after {
                    // Trick to prevent width change on hover/focus
                    content: "";
                    display: block;
                    position: absolute;
                    width: calc(100% + 8px);
                    height: 100%;
                    z-index: -1;
                    background-color: inherit;
                }
            }
        }

        .icon {
            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &--loading {
        pointer-events: none;
        gap: 16px;
        opacity: 0.7;

        &::after {
            content: "";
            width: 24px;
            height: 24px;
            margin-right: -12px;
            border: 2px solid $white;
            border-bottom-color: transparent;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
        }

        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        .icon {
            display: none;
        }

        &.btn--primary {
            &.btn--special {
                &::after {
                    border: 2px solid $black;
                    border-bottom-color: transparent;
                }
            }
        }

        &.btn--secondary {
            &::after {
                border: 2px solid $primary;
                border-bottom-color: transparent;
            }
        }
    }
}
