.page-404{
    &__wrapper{
        display: flex;
        flex-direction: column;
        gap: 56px;
        padding: 160px 0;

        @include max-lg{
            gap: 32px;
            padding: 80px 0;
        }
    }

    &__title{
        @extend .title;
        @extend .title--h1;
        color: $primary
    }

    &__subtitle{
        @extend .title;
        @extend .title--h2;
        color: $primary
    }

    &__text{
        @extend .ingress;
        color: $primary
    }
}