.collaborators{
    margin-bottom: 120px;

    &:last-child{
        margin-bottom: 0;
    }

    @include max-lg{
        margin-bottom: 80px;
    }

    &__title{
        margin-bottom: 24px;
        color: $primary;
        @extend .title;
        @extend .title--h3;

        @include max-lg{
            margin-bottom: 16px;
        }
    }

    &__list{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
    }

    &__list-item{
        figure{
            padding: 16px;
            background-color: $white;
            border: 1px solid $gray-50;

            @include max-lg{
                padding: 8px;
            }

            img{
                display: block;
                width: auto;
                height: 80px;

                @include max-lg{
                    height: 40px;
                }
            }
        }
    }
}