.modal{
    .modal-dialog{
        max-width: $screen-xl + $grid-gutter-width;
        margin-top: 80px;
        margin-bottom: 80px;
        min-height: calc(100% - 80px * 2);;

        @include max-xxl{
            margin-right: 80px;
            margin-left: 80px;
        }

        @include max-lg{
            margin: 16px;
        }

        @include max-md{
            margin-right: 0px;
            margin-left: 0px;
        }
    }

    &__content{
        padding: 32px;
        background-color: $color-bg;
        @extend .modal-content;

        @include max-sm{
            padding: 16px;
        }
    }

    &__header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 24px;
        margin-bottom: 24px;
    }

    &__title{
        @extend .title;
        @extend .title--h3;
        color: $primary;
    }

    &__body{
        & > audio{
            display: block;
            margin: 0 auto;
        }
    }
}

.modal-backdrop{
    &.show{
        opacity: 0.6;
    }
}