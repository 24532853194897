.category-summary{
    &__wrapper{
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include max-lg{
            gap: 24px;
        }
    }

    &__heading{
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include max-lg{
            gap: 16px;
        }
    }

    &__title{
        color: $primary;
        @extend .title;
        @extend .title--h2;
    }

    &__ingress{
        @extend .body-text;
    }

    &__card{
        padding: 40px;

        @include max-lg{
            padding: 32px;
        }

        &--high{
            background-color: $light-green;
        }

        &--medium{
            background-color: $light-rust;
        }

        &--low{
            background-color: $dark-rust;
            color: $white;
        }
    }

    &__card-title{
        margin-bottom: 16px;
        @extend .title;
        @extend .title--h3;
    }

    &__card-text{
        @extend .body-text;
    }

    &__card-label{
        margin-top: 30px;
        @extend .body-text;
        @extend .body-text--bold;

        @include max-lg{
            margin-top: 24px;
        }
    }
    
    &__card-score{
        @extend .body-text;
    }
}