// ---------------------------------------------------------------------- //
// Font: Roboto                                      //
// ---------------------------------------------------------------------- //
// Light        (300)
// Regular      (400)
// Medium		(500)
// Bold         (700)
// ---------------------------------------------------------------------- //

// Font weights
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;

$sans-serif: 'Hanken Grotesk', sans-serif;

// Main text color
$main-text-color: $black;

html,
body {
	font-family: $sans-serif;
	font-size: 16px;
	font-weight: $fw-regular;
	color: $main-text-color;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	// hyphens: none;
	// -moz-hyphens: none !important;
	// -ms-hyphens: none !important;
	// -webkit-hyphens: none !important;
	// word-wrap: break-word;
}

a {
	text-decoration: none;
	color: $main-text-color;

	&:hover {
		@include min-lg{
			text-decoration: none;
			color: $main-text-color;
		}
	}
}

.hero-text{
	font-size: 60px;
	line-height: 1.2;
	font-weight: $fw-semibold;

	@include max-lg{
		font-size: 44px;
		font-weight: $fw-semibold;
	}
}

.title{
	display: block;
	
	&--h1{
		font-size: 48px;
		line-height: 1.2;
		font-weight: $fw-semibold;
		
		@include max-lg{
			font-size: 36px;
		}
	}
	
	&--h2{
		font-size: 38px;
		line-height: 1.2;
		font-weight: $fw-semibold;

		@include max-lg{
			font-size: 28px;
		}
	}

	&--h3{
		font-size: 30px;
		line-height: 1.3;
		font-weight: $fw-semibold;

		@include max-lg{
			font-size: 22px;
		}
	}

	&--h4{
		font-size: 24px;
		line-height: 1.3;
		font-weight: $fw-semibold;

		@include max-lg{
			font-size: 20px;
		}
	}

	&--h5{
		font-size: 22px;
		line-height: 1.4;
		font-weight: $fw-semibold;

		@include max-lg{
			font-size: 18px;
		}
	}

	&--h6{
		font-size: 20px;
		line-height: 1.5;
		font-weight: $fw-semibold;

		@include max-lg{
			font-size: 16px;
		}
	}
}

.ingress{
	font-size: 24px;
	line-height: 1.5;
	font-weight: $fw-regular;
	
	@include max-lg{
		font-size: 20px;
	}
}

.body-text{
	font-size: 20px;
	line-height: 1.5;
	font-weight: $fw-regular;

	@include max-lg{
		font-size: 16px;
	}

	&--bold, strong{
		font-weight: $fw-semibold;
	}

	&--small{
		font-size: 16px;
		line-height: 1.5;

		@include max-lg{
			font-size: 14px;
		}
	}
}

// Alternative mixin for the body-text class. this can be used to avoid increasing css size unnecessarily
@mixin body-text($modifier: null) {
	@if $modifier != 'small'{
		font-size: 20px;
		line-height: 1.5;
	}

	@if $modifier =='bold' {
		font-weight: $fw-semibold;
	}

	@else if $modifier =='small' {
		font-size: 16px;
		line-height: 1.5;
	}

	@else {
		font-weight: $fw-regular;
	}

	@include max-lg {
		@if $modifier =='small' {
			font-size: 14px;
		}

		@else {
			font-size: 16px;
		}
	}

	@if $modifier !=null and $modifier !='bold' and $modifier !='small' {
		@error "Invalid modifier provided for @body-text mixin. Please use `bold` or `small`.";
	}
}

@mixin link-text($modifier: null){
	padding: 0px 2px;
	color: $black;
	text-decoration: underline;
	text-decoration-color: $black;
	text-underline-offset: 5px;
	text-decoration-thickness: 1px;

	&:hover, &:focus-visible{
		@include min-lg{
			background-color: $black;
			color: $white;
			outline: none;
			box-shadow: none !important;
			text-decoration-color: transparent;
	
			.icon{
				path{
					fill: $white;
				}
			}
		}
	}

	.icon{
		path{
			fill: $black;
		}
	}

	@if $modifier == "dark" {
		color: $white;
		text-decoration-color: $white;

		.icon{
			path{
				fill: $white;
			}
		}

		&:hover, &:focus-visible{
			background-color: $white;
			color: $black;
			text-decoration-color: transparent;
	
			.icon{
				path{
					fill: $black
				}
			}
		}
	}

	@if $modifier == "black" {
		&:not(:hover):not(:focus) {
			color: $black;
			
			.html-area & {
				color: $black;
			}
		}
	}
}

figcaption {
	margin-top: 16px;
	hyphens: none;
	@extend .body-text;
	@extend .body-text--small;

	@include max-lg{
		margin-top: 8px;
		font-size: 16px;
	}
}

em{
	font-style: italic;
}

// New Mixins for Velgetke text styles

