.search-results{
    padding: 80px 0 16px;

    @include max-md{
        padding-bottom: 0px;
    }

    // &__wrapper{
    //     // & > * {
    //     //     max-width: 700px;
    //     // }
    // }

    &__heading{
        margin-bottom: 56px;
        color: $black;
        @extend .title;
        @extend .title--h1;

        @include max-lg{
            margin-bottom: 40px;
        }
    }

    &__input{
        margin-bottom: 16px;

        @include max-lg{
            margin-bottom: 0;
        }
    }

    &__info{
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-top: 40px;
        margin-bottom: 56px;

        @include max-lg{
            margin-bottom: 40px;
        }
    }

    &__filter{
        // max-width: 714px;
        margin-top: 56px;
        margin-bottom: 56px;
        
        @include max-lg{
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 56px;
    
        @include max-lg{
            gap: 32px;
        }
    }

    // Search result card...
    &__item{
        position: relative;

        // Manually added the hover effect to the "tertiary btn"
        &:hover{
            .search-results__item-title{
                @include min-lg{
                    background-color: $black;
                    color: $white;
                    outline: none;
                    box-shadow: none !important;
                    text-decoration-color: transparent;
            
                    .icon{
                        path{
                            fill: $white;
                        }
                    }
                }
            }
        }

        &:focus-within{
            outline: 2px solid $primary;
            outline-offset: 8px;
        }
    }

    &__item-link{
        &:before {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    
        &:focus-visible {
            outline: none;
        }

        // Manually added the arrow animation
        &--internal{
            &:hover, &:focus-visible{
                & + .search-results__item-title{
                    &::after{
                        // Trick to prevent width change on hover/focus
                        content: "";
                        display: block;
                        position: absolute;
                        width: calc(100% + 8px);
                        height: 100%;
                        z-index: -1;
                        background-color: inherit;
                    }

                    .icon{
                        transform: translateX(8px);
                    }
                }
            }
        }
    }

    &__item-title{
        margin-bottom: 8px;
        @extend .btn;
        @extend .btn--tertiary;
        @extend .btn--special;
        align-items: flex-start;
        z-index: -1;

        .icon{
            margin-top: 7px;

            @include max-lg{
                display: none;
            }
        }
    }

    &__item-tags{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 8px;

        li{
            display: flex;
            flex-direction: row;
            color: $black;
            @extend .body-text;
            @extend .body-text--small;

            &:not(:last-child){
                &::after{
                    content: "|";
                    margin: 0px 8px;
                }
            }
        }
    }

    &__item-intro{
        @extend .body-text;
        @extend .body-text--small;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        
        @include max-md{
            -webkit-line-clamp: 5;
        }
    }
}