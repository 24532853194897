// This component is used in the "event-overview" part and in the "events" part
.event-list{
    &__list{
    }
    
    &__item{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px 0px 72px;
        border-bottom: 2px solid $cool-green;
        
        @include max-lg{
            padding-bottom: 56px;
            gap: 16px;
        }
        
        @include max-sm{
            padding-bottom: 116px;
        }
        
        &:first-child{
            border-top: 2px solid $cool-green;
        }
    }

    &__item-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        gap: 32px;

        @include max-lg{
            gap: 24px;
        }

        & > a{
            flex-shrink: 0;

            @include max-sm{
                position: absolute;
                bottom: 56px;
            }
        }
    }

    &__item-title{
        a{
            @extend .title;
            @extend .title--h3;
        }
    }

    &__item-subtitle{
        @extend .body-text;
        @extend .body-text--bold;
    }
    
    &__item-details{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__item-detail{
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 16px;
        row-gap: 8px;

        p{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 8px;
            @extend .body-text;

            .icon{
                flex-shrink: 0;
                margin-top: 3px;

                @include max-lg{
                    margin-top: 0;
                }
            }
        }
    }

    &__item-figure{
        max-width: 400px;
    }
}