.image-link{
    padding: 80px 0;

    @include max-lg{
        padding: 40px 0;
    }

    &--dark{
        .image-link__card{
            background-color: $dark-green;
        }

        .image-link__title{
            color: $sprout-green;
        }

        .image-link__text{
            color: $white;
        }

        .button-group{
            margin-top: 8px;

            .btn{
                @extend .btn--primary;
            }
        }
    }

    &--reverse{
        .image-link__card{
            @include min-xl{
                padding-left: 110px;
            }

            @include min-md{
                flex-direction: row-reverse;
            }
        }
    }

    &__card{
        display: flex;
        flex-direction: row;
        gap: 94px;
        background-color: $light-green;
        padding: 80px 56px;
        border-radius: 0 56px 0 56px;
        overflow: hidden;

        @include max-lg{
            padding: 40px;
            gap: 56px;
        }

        @include max-md{
            padding: 0;
            flex-direction: column;
            gap: 0px;
        }

        & > * {
            flex: 1;
            flex-shrink: 0;
        }
    }

    &__figure{
        max-width: 400px;
        border-radius: 0 40px 0 40px;
        overflow: hidden;

        @include max-md{
            max-width: 100%;
            border-radius: 0 56px 0 0;
        }
    }

    &__content{
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include max-md{
            padding: 40px 32px 56px;
        }
    }

    &__title{
        color: $primary;
        max-width: 560px;
        @extend .title;
        @extend .title--h3;
    }

    &__text{
        max-width: 560px;
        hyphens: none;
        @extend .body-text;
    }
}