.article-cards{
    padding: 80px 0;

    @include max-lg{
        padding: 40px 0;
    }
    
    &__wrapper{
        row-gap: 40px;
        margin-bottom: 40px;

        @include max-xl{
            margin-bottom: 24px;
        }
    }

    &__title{
        margin-bottom: 40px;

        @include max-xl{
            margin-bottom: 24px;
        }

        h2{
            display: inline-block;
            vertical-align: middle;
            color: $black;
            @extend .title;
            @extend .title--h2;
            
            @include max-lg{
                display: block;
            }
        }

        .arrow-link{
            display: inline-block;
            margin-left: 24px;
            vertical-align: middle;

            @include max-lg{
                display: block;
                margin-top: 8px;
                margin-left: 0;
            }
        }
    }

    &__card{
        position: relative;
        display: block;
        padding: 24px;
        color: $black;
        border-radius: 24px;

        @include max-xl{
            padding: 16px;
        }

        &:hover, &:focus-within {
            background-color: $black;
            color: $white;

            .article-cards__card-tags{
                li{
                    color: $white;

                    &::after{
                        color: $white;
                    }
                }
            }

            .article-cards__card-title{
                text-decoration-color: $white;
            }

            .article-cards__card-arrow{
                transform: translateX(8px);

                path{
                    fill: $white;
                }
            }
        }
    }

    &__card-link {
        &:before {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
        }
    
        &:focus-visible {
            outline: none;
    
            &:before {
                outline: auto;
            }
        }
    }

    &__card-figure{
        img[src$=".svg"] {
            background-color: white; // background-color for svg and transparent images
        }

        margin-bottom: 16px;

        img {
            border-radius: 22px;
        }
    }

    // &__card-tags{
    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: wrap;
    //     margin-bottom: 8px;

    //     li{
    //         position: relative;
    //         display: flex;
    //         flex-direction: row;
    //         align-items: baseline;
    //         @extend .body-text;
    //         @extend .body-text--bold;

    //         &:not(:last-child){
    //             &::after{
    //                 content: "|";
    //                 display: block;
    //                 margin: 0 8px;
    //                 color: $cool-green;
    //             }
    //         }
    //     }
    // }

    &__card-title{
        margin-bottom: 16px;
        @extend .title;
        @extend .title--h3;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;
        
        @include max-xl{
            text-decoration-thickness: 1px;
            text-underline-offset: 3px;
        }
    }

    &__card-ingress{
        margin-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @extend .body-text;
    }

    &__card-info{
        margin-bottom: 16px;
        @extend .body-text;
        @extend .body-text--small;
    }

    &__card-arrow{
        transition: transform 0.2s;
        path{
            fill: $primary;
        }
    }
}