.article-hero{
    padding-top: 80px;
    padding-bottom: 80px;

    @include max-lg{
        padding-top: 40px;
    }

    &__figure{
        margin-bottom: 80px;

        @include max-lg{
            margin-bottom: 40px;
        }
    }

    &__content{
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include max-lg{
            gap: 24px;
        }
    }

    &__title{
        @extend .title;
        @extend .title--h1;
        color: $black;
        hyphens: none;
    }

    &__ingress{
        @extend .ingress;
        hyphens: none;
    }

    &__info{
        &:last-child{
            .article-info{
                padding-bottom: 0;
            }
        }
    }

    &__table-of-contents{
        margin-top: 40px;
    }

    & + .rich-text{
        padding-top: 0;
    }
}