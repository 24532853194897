.article-info{
    padding: 40px 0;

    &__wrapper{
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px 24px;
        border-left: 2px solid $black;
        color: $black;

        @include max-lg{
            gap: 4px;
        }
    }

    &__author{
        @extend .body-text;
    }

    &__publish-info{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;

        @include max-lg{
            row-gap: 4px
        }

        p{
            @extend .body-text;
            @extend .body-text--small;
        }
    }
    
    &__aditional-info{
        @extend .body-text;
        @extend .body-text--small;
    }
}