.content-tabs{
    position: relative;
    display: flex;
    align-self: flex-start;
    border: 2px solid $primary;
    padding: 2px;
    border-radius: 500px;

    &:focus-within{
        &:not(:hover){
            &::before{
                content: "";
                display: block;
                position: absolute;
                left: -6px;
                top: -6px;
                width: calc(100% + 12px);
                height: calc(100% + 12px);
                border: 2px solid $primary;
                border-radius: 500px;
                pointer-events: none;
    
            }
        }
    }

    &__button {
        // Remove button style when not "active"
        &:not([aria-selected="true"]){
            background-color: transparent;
            border: none;
            color: $primary;
            
            &:focus, &:focus-visible, &:hover{
                background-color: transparent;
                text-decoration: underline;
                text-decoration-thickness: 1px;
                text-underline-offset: 2px;
                color: $primary;
                box-shadow: none;
            }

            &:active{
                background-color: transparent;
                color: $primary;
                box-shadow: none;
            }
        }

        &:focus-visible{
            box-shadow: 0 0 0 2px $light-green, 0 0 0 4px $dark-green;
        }

        @include max-lg{
            padding-right: 32px;
            padding-left: 32px;
        }
    }
    
    &__content {
        display: none;
        
        &.active{
            display: block;
        }
    }
}