// Style for filters used on different pages
.filter{
    &__title{
        margin-bottom: 16px;

        & > *{
            @extend .body-text;
            @extend .body-text--bold;
        }
    }

    &__col{
        @include max-md{
            &:not(:last-child){
                margin-bottom: 24px;
            }
        }
    }

    .input-group{
        &--radio{
            flex-direction: row;
            flex-wrap: wrap;
            gap: 24px;
            margin-bottom: 0;
            
            @include max-md{
                flex-direction: column;
            }

            .radio{
                margin-bottom: 0;
            }
        }
    }

    &__reset{
        margin-top: 16px;
    }
}