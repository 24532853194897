html{
    scroll-behavior: smooth;
}

body{
    background-color: $color-bg;
    @extend .hyphenate;
}

#main{
    padding-bottom: 160px;

    @include max-lg{
        padding-bottom: 120px;
    }
}

.image-notch{
    position: relative;

    &::before, &::after{
        content: "";
        display: block;
        position: absolute;
        height: 16px;
        background-color: $color-bg;

        @include max-lg{
            height: 8px;
        }
    }

    &::before{
        top: 0;
        left: 0;
        width: 36.5%;
    }

    &::after{
        bottom: 0;
        right: 0;
        width: 63.5%;
    }

    &--portrait{
        &::before, &::after{
            width: 16px;
    
            @include max-lg{
                width: 8px;
            }
        }
    
        &::before{
            top: 0;
            left: 0;
            height: 63.5%;
        }
        
        &::after{
            bottom: 0;
            right: 0;
            height: 36.5%;
        }
    }

    &--square{
        &::before, &::after{
            width: 16px;
    
            @include max-lg{
                width: 8px;
            }
        }
    
        &::before{
            top: 0;
            left: 0;
            height: 59%;
        }
        
        &::after{
            bottom: 0;
            right: 0;
            height: 41%;
        }
    }

    .bg-light-green{
        .image-notch{
            &::before, &::after{
                background-color: $light-green;
            }
        }
    }
}

.hyphenate {
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

/* Hiding class, making content visible only to screen readers but not visually */
/* "sr" meaning "screen-reader" */
.sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
}

// make the macro fill the container width
.offgrid-macro{
    margin-left: -53%;
    
    @include min-xxl{
        width: 1278px;
        max-width: calc(100vw - 175px);
    }

    @include max-xxl{
        width: calc(100vw - 175px);
        margin-left: -53.5%;
    }

    @include max-xl{
        margin-left: -26.5%;
    }

    @include max-lg{
        width: 100%;
        gap: 16px;
        margin-left: 0;
    }
}