.help-card{
    padding: 80px 0;
    overflow-x: hidden;

    @include max-lg{
        padding: 40px 0;
    }

    &--full {
        padding-block: 0;
    }    

    &__wrapper{
        padding: 80px 32px;
        border-radius: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 80px;

        @include max-lg{
            flex-direction: column;
            gap: 32px;
            padding: 56px 32px;
        }

        $colors: (
            green: $green-40,
            blue: $blue-40,
            yellow: $yellow-40,
        );

        @each $color-name, $color in $colors {
            .help-card--#{$color-name} & {
                background-color: #{$color};
            }
        }

        .help-card--no-bg & {
            outline: 2px solid $black;
        }

        .help-card--reverse & {
            @include min-lg {
                flex-direction: row-reverse;
            }
        }

        .help-card--full & {
            margin-inline: -100%;
            padding-inline: 16px;
            border-radius: 0;

            @include max-xl {
                margin-inline: -80px;
            }

            @include max-lg {
                margin-inline: -16px;
            }
        }
    }

    &__figure{
        max-width: 268px;
        height: 268px;
        flex-shrink: 0;

        @include max-md {
            max-width: 200px;
            height: 200px;
        }

        img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }

        .help-card--full & {
            max-width: 500px;
            height: 500px;
            
            @include max-md {
                max-width: 250px;
                height: 250px;
            }
        }
    }

    &__content{
        max-width: 560px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include max-lg {
            gap: 16px;
        }

        .button-group{
            gap: 24px;

            @include max-md {
                flex-direction: row;
            }
        }

        .help-card--full & {
            max-width: 473px;
        }
    }
    
    &__title{
        color: $black;
        @extend .title;
        @extend .title--h3;
    }

    &__text{
        @extend .body-text;

        a{
            @include link-text;
        }
    }
}