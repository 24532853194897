.images-macro{
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 40px;
    padding: 40px 0;
    @extend .offgrid-macro;

    @include max-lg{
        column-gap: 16px;
    }

    @include max-sm{
        flex-direction: column;
        row-gap: 40px;
    }

    &__figure{
        width: calc(50% - 20px);

        @include max-lg{
            width: calc(50% - 8px);
        }

        @include max-sm{
            width: 100%;
        }
    }

    & + .images-macro{
        @include max-lg{
            padding-top: 0;
        }
    }
}