.link-list-part{
    padding: 80px 0;

    @include max-lg{
        padding: 40px 0;
    }

    .row{
        & + .row{
            margin-top: 80px;

            @include max-md{
                margin-top: 40px;
            }
        }
    }

    &__wrapper{
        padding: 160px 0;
        background-color: $light-green;

        @include max-lg{
            padding: 80px 0;
        }

        &.image-notch{
            @include max-lg{
                &::before, &::after{
                    display: none;
                }
            }
        }
    }

    &__left{
        @include max-md{
            margin-bottom: 16px;
        }

        & > * {
            @include min-md{
                max-width: 270px;
            }
        }
    }

    &__title{
        color: $primary;
        @extend .title;
        @extend .title--h2;
    }

    & + .link-list-part{
        padding-top: 40px;
    }
}