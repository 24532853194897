.input-group{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    &:last-child{
        margin-bottom: 0;
    }

    &__label{
        @extend .label;

        & + .radio, & + .checkbox{
            margin-top: 12px;
        }

        .status{
            font-size: 14px;
            padding: 1px 5px;
            margin-left: 5px;
            display: inline-block;
            transform: translateY(-1px);
        }
    }
    
    &__description{
        margin-bottom: 8px;
        hyphens: none;
        @include body-text('small');
    }

    textarea,
    input[type="text"],
    input[type="number"],
    input[type="tel"],
    input[type="email"],
    input[type="password"],
    input[type="search"],
    select {
        @extend .text-input;
    }

    &__clear{
        visibility: hidden;
        position: relative;
        width: 24px;
        margin-right: -24px;
        transform: translateX(-32px);
        margin-top: 4px;
        transition: visibility 0.5s;

        &:focus-visible{
            outline: none;

            &::before{
                outline: 2px solid $black;
            }
        }

        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }

    textarea{
        @extend .textarea;
    }

    &__chars{
        margin-left: auto;
        margin-top: 8px;
        @include body-text('small');
    }

    &--search{
        .search-input-wrapper{
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: row;

            &:focus-within{
                .input-group__clear--active{
                    visibility: visible;
                }

                .btn{
                    margin-left: -2px;

                    &:hover{
                        margin-left: -2px;
                    }
                }
            }

            input{
                &[type="search"]{
                    padding-right: 40px;
                }

                &:focus-visible{
                    outline: 2px solid $black;
                    outline-offset: 2px;
                }
            }
            
            .btn{
                flex-shrink: 0;
                margin-left: -1px;
                padding-right: 16px;
                padding-left: 16px;
                border-radius: 0;
                border-width: 1px;

                &:hover{
                    box-shadow: inset 0 0 0 1px $black;
                    margin-left: -1px;
                }

                &:focus-visible{
                    background-color: transparent;
                }
    
                .icon{
                    margin: 0;
                    width: 24px;
                    height: 24px;
                }

                &--secondary {
                    &:hover {
                        background-color: $green-20;
                        color: $black;
                    }
                }
            }
        }
    }

    .select-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        
        select{
            appearance: none;
            border-radius: 0;
            padding-right: 72px;
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: $black;

            @include max-lg{
                padding-right: 60px;
            }

            &:hover{
                background-color: transparent;
                box-shadow: none;

                &+.select-arrow{
                    box-shadow: inset 0 0 0 1px $dark-green;
                    background-color: $green-20;
                }
            }
            
            &:focus{
                &+.select-arrow{
                    box-shadow: inset 0 0 0 1px $dark-green;
                }
            }
        }

        .select-arrow{
            flex-shrink: 0;
            border-radius: 0;
            padding: 0 15px;
            margin-left: -56px;
            border-width: 1px;
            pointer-events: none;

            .icon{
                width: 24px;
                height: 24px;
                margin: 0;
            }

            @include max-lg{
                // width: 48px;
                padding: 0 11px;
                margin-left: -48px;
            }
        }
    }

    .option-group{
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 32px;

        @include max-lg{
            gap: 24px;

        }

        &__option{
            input{
                position: absolute;
                opacity: 0;

                &:focus-visible{
                    & + label{
                        box-shadow: 0 0 0 2px $color-bg, 0 0 0 4px $dark-green;
                    }
                }

                &:checked{
                    & + label{
                        background-color: $dark-green;
                        color: $white;
                    }
                }
            }
    
            label{
                cursor: pointer;
                padding-right: 40px !important;
                padding-left: 40px !important;
                border-radius: 8px !important;
                border-color: $dark-green !important;
                @extend .btn;
                @extend .btn--secondary;

                @include max-lg{
                    padding-right: 24px !important;
                    padding-left: 24px !important;
                }
            }
        }
    }

    // Telephone input (international
    .iti{ /* class added by the "intl-tel-input" lib */
        &:not(:focus-within){
            .iti__flag-container{
                &:hover{
                    box-shadow: 0 0 0 1px $black;
                }
            }
        }
        
        &__flag-container{
            width: 84px;
            border-right: 1px solid $black;
            padding: 0;
        }
    
        &__selected-flag{
            justify-content: center;
            gap: 8px;
            padding: 0px 4px 0px 12px;

            @include max-lg{
                padding: 0px 0px 0px 4px;
            }

            &:focus-visible{
                box-shadow: 0 0 0 2px $color-bg, 0 0 0 4px $black;
                outline: none;
            }
        }

        &__arrow{
            width: 24px;
            height: 24px;
            border: none;
            margin-left: 0;
            background-image: url('./../images/icons/ChevronDown.svg');
            background-repeat: no-repeat;
        }

        &--separate-dial-code{
            .iti__selected-flag{
                gap: 6px;
                background-color: transparent;
            }

            .iti__selected-dial-code{
                margin-right: -5px;
                margin-left: 0;
                white-space: nowrap;
            }

            .iti__flag-container{
                width: 104px;
            }
        }
    }

    &--50{
        width: 50%;

        @include max-sm{
            width: 70%;
        }
    }
    
    &--75{
        width: 75%;

        @include max-sm{
            width: 80%;
        }
    }
}

// Form-info
.form-info{
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin: 16px 0;
    padding: 4px 8px;
    background-color: $light-blue;

    &:first-child{
        margin-top: 0;
    }

    &:last-child{
        margin-bottom: 0;
    }
    
    @include max-lg{
        gap: 8px;
    }

    &__icon{
        flex-shrink: 0;
    }

    &__text{
        @include body-text;
    }

    .input-group & {
        align-self: flex-start;
    }
}

.label{
    margin-bottom: 4px;
    hyphens: none;
    @include body-text;
}

.text-input{
    width: 100%;
    border: 1px solid $black;
    padding: 9px 16px;
    background-color: transparent;
    @include body-text;

    @include max-lg{
        padding: 12px;
    }

    &:hover{
        background-color: $green-20;
        box-shadow: inset 0 0 0 1px $black;
    }
    
    &:focus, &:active {
        outline: 2px solid $black;
        outline-offset: 2px;
        box-shadow: inset 0 0 0 1px $black;
    }
    
    &.error{
        border: 2px solid $red-80;
        margin: -1px; // negative margin to compensate for the width added by the border
        
        &:hover{
            box-shadow: inset 0 0 0 1px $red-80;
            background-color: $red-20;
        }
        
        &:focus, &:active {
            outline: 2px solid $red-80;
            box-shadow: inset 0 0 0 1px $red-80;
        }
    }
}

.textarea{
    padding: 16px;
    min-height: 133px;
    color: $gray-80;
    max-width: 100%;
    max-height: 400px;
    
    @include max-lg{
        padding: 12px;
        min-height: 120px;
    }
}

// Custom Radio and Checkbox mutual style
.radio,
.checkbox {
    margin-bottom: 16px;

    @include max-lg {
        margin-bottom: 8px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.custom-radio,
.custom-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    margin: 0;

    &~label, &~span {
        position: relative;
        display: block;
        padding: 1px 0 1px 48px;
        cursor: pointer;
        @include body-text;

        @include max-lg {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        a {
            text-decoration: underline;
            text-underline-offset: 2px;
        }

        &:hover {
            &::before {
                border-width: 2px;
                background-color: $green-20;
            }
        }
    }

    &~label:before, &~span:before {
        content: "";
        position: absolute;
        left: 2px;
        vertical-align: text-top;
        border: 1px solid $black;
    }

    // Checkmark
    &~label:after, &~span:after  {
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        transition: 0.1s;
    }

    // Disabled state label.
    &:disabled~label,
    &:disabled~label>*,
    &:disabled~span,
    &:disabled~span>* {
        color: transparentize($black, 0.6);
        pointer-events: none;
        cursor: default;
    }

    // Focus state
    &:focus-visible~label,
    &:focus-visible~span {
        &::before {
            border-width: 2px;
            box-shadow: 0 0 0 2px $color-bg, 0 0 0 4px $moss-green;
        }
    }

    &.error{
        &:not([disabled]){
            &~label, &~span {
                color: $red-80;
    
                &::before{
                    border-color: $red-80;
                    background-color: $red-20;
                }
            }
        }
    }
}

// Custom Radio
.radio {
    .custom-radio {
        // Box.
        &~label:before,
        &~span:before {
            top: 4px;
            border-radius: 100%;
            width: 26px;
            height: 26px;
        }

        // Checkmark
        &~label:after,
        &~span:after {
            top: 10px;
            left: 8px;
            width: 14px;
            height: 14px;
            border-radius: 100%;
            background-color: transparent;
        }

        // Checked box
        &:checked~label:after,
        &:checked~span:after {
            background-color: $black;
        }

        // Disabled box
        &:disabled~label:before,
        &:disabled~span:before {
            border-color: transparentize($black, 0.6);
        }

        // Disabled checkmark
        &:checked {
            &:disabled~label:after,
            &:disabled~span:after {
                background-color: transparentize($black, 0.6) !important;
            }
        }
    }
}

// Custom Checkbox
.checkbox {
    .custom-checkbox {
        // Box.
        &~label:before,
        &~span:before {
            top: calc(50% - 15px);
            width: 30px;
            height: 30px;
            border-radius: 2px;
        }

        // Checkmark image
        &:checked~label::before,
        &:checked~span::before {
            background-color: $moss-green;
        }

        // Checkmark image
        &:checked~label:after,
        &:checked~span:after {
            content: "";
            background-color: transparent;
            position: absolute;
            left: 14px;
            top: calc(50% - 10px);
            width: 7px;
            border-bottom: 2px solid $white;
            height: 15px;
            border-right: 2px solid $white;
            transform: rotate(45deg);
            -o-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }

        // Disabled box
        &:disabled~label:before,
        &:disabled~span:before {
            border-color: transparentize($black, 0.6);
        }

        // Checkd + Disabled
        &:checked {
            &:disabled~label::before,
            &:disabled~span::before {
                border: none;
                background-color: transparentize($black, 0.6) !important;
            }
        }
    }
}

.input-file-wrapper{
    position: relative;
    text-align: center;
    padding: 32px;
    
    &--dragover{
        &::before{
            border-width: 3px !important;
        }
    }
    
    &::before{
        pointer-events: none;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px dashed $black;
    }

    & > p{
        margin-bottom: 32px;
        @include body-text;

        @include max-lg{
            display: none;
        }
    }

    input[type="file"]{
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        width: 1px;
        height: 1px;
        overflow: hidden;
        opacity: 0;
    }
}

.file-list{
    &__item{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 32px;
        margin-top: 16px;

        @include max-lg{
            flex-direction: column;
            gap: 8px;
        }

        p{
            @include body-text;
            text-align: left;
        }

        .btn{
            flex-shrink: 0;

            @include max-lg{
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .icon{
                width: 24px;
                height: 24px;

                path{
                    fill: $black;
                }
            }
        }
    }
}

.error-text{
    margin-top: 4px;
    @include body-text('small');
    color: $red-80 !important;
}