.breadcrumbs{
    padding: 24px 0;
    
    @include max-lg{
        padding: 16px 0;
    }

    &--light-green{
        background-color: $light-green;
    }
    
    &__list{
        display: flex;
        flex-direction: row;
    }

    &__item{
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        @include min-lg{
            max-width: 420px;
        }

        @include max-lg{
            &:not(:nth-last-child(2)){
                display: none;
            }
        }

        &::after{
            content: "";
            display: block;
            flex-shrink: 0;
            width: 24px;
            height: 24px;
            margin: 3px 8px 0;
            background-image: url('./../images/icons/ChevronRight.svg');
            background-repeat: no-repeat;

            @include max-lg{
                display: none;
            }
        }

        &:last-child{
            &::after{
                display: none;
            }

            a{
                pointer-events: none;
                cursor: default;
                text-decoration: none;
            }
        }

        a{
            @extend .body-text;
            @include link-text;
            color: $primary;

            @include max-lg{
                display: flex;
                padding: 16px 2px;

                &::before{
                    content: "";
                    display: block;
                    flex-shrink: 0;
                    width: 24px;
                    height: 24px;
                    background-image: url('./../images/icons/ChevronLeft.svg');
                    background-repeat: no-repeat;  
                }
            }
        }
        
        > .icon{
            margin-top: 3px;

            > *{
                fill: $primary;
            }
        }
    }
}